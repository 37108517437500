import React, { Component } from 'react';
import { Table, Menu, Button, Input, Dropdown, message } from 'antd';
import {  DashOutlined, EditOutlined } from '@ant-design/icons';
import { debounce as _debounce } from 'lodash';
import clipboardJS from 'clipboard';
import LayoutHOC from '../../components/layout';
import DrawerUnit from '../../components/drawer';
import CreateEntryTableUnit from './createEntryTable';
import UpdateEntryTableUnit from './updateEntryTable';
import CreateEntryFieldUnit from './createEntryField';
import UpdateEntryFieldUnit from './updateEntryField';
import BaselineDictionaryUnit from './baselineDictionary';
import WarningUnit from '../common/warning';
import api from '../../services/api';
import './common.less';

declare let global: any;
export interface IStates {
  tableLoading: boolean,
  loading: boolean,
  visible: boolean,
  subModalTitle: any,
  subModalComponent: any,
  selectedApplicationUUID: any,
  orginEntryTables: any,
  entryTables: any,
  selectedEntryTable: any,
  entryFields: any,
  contentHeight: number,
}

const initSelectedEntryTable = {
  id: null,
  name: null,
  describe: null
};

class Home extends Component<any, IStates> {
  constructor(props: any) {
    super(props);

    // 已选择的应用
    let selectedApplicationUUID = global.G_GET_APP_UUID();

    this.state = {
      tableLoading: false,
      loading: false,
      visible: false,
      subModalTitle: null,
      subModalComponent: null,
      selectedApplicationUUID,
      entryTables: [], // 数据表
      orginEntryTables: [], // 数据表（用于筛选）
      selectedEntryTable: {
        ...initSelectedEntryTable
      }, // 当前选择的数据表
      entryFields: [], // 当前数据表字段
      contentHeight: 300,
    }
  }

  componentDidMount() {

    const { selectedApplicationUUID } = this.state;

    if (selectedApplicationUUID) {
      this.handleGetEntryTables(selectedApplicationUUID);
    }

    this.handleWindowHeight();
    window.onresize = _debounce(() => {
      this.handleWindowHeight();
    }, 100);

  }

  handleWindowHeight = () => {
    let contentHeight = window.innerHeight - 270;
    this.setState({ contentHeight });
  }

  /**
   * 获取数据表
   * @param applicationUUID 应用UUID
   * @param isCancelCloseSubModal 是否取消关闭子Modal操作
   */
  handleGetEntryTables = async (applicationUUID?: any, isCancelCloseSubModal?: boolean) => {

    let currentApplicationUUID = applicationUUID || this.state.selectedApplicationUUID;

    this.setState({ tableLoading: true });

    let { errcode, data }: any = await api.getApplicationEntryTables(currentApplicationUUID);

    if (errcode >= 0) {
      let thisSelectedEntryTable = this.state.selectedEntryTable;

      let refreshHash: any = {
        orginEntryTables: data.application.EntryTables,
        entryTables: data.application.EntryTables,
        selectedApplicationUUID: currentApplicationUUID,
        visible: false,
        tableLoading: false,
        selectedEntryTable: thisSelectedEntryTable,
      };

      if (!isCancelCloseSubModal) {
        refreshHash.subModalTitle = null;
        refreshHash.subModalComponent = null;
      }

      // 同时更新已选择的数据表记录
      if (thisSelectedEntryTable.id) {

        let hasSelectedEntryTable = refreshHash.entryTables.find((o: any) => o.id === thisSelectedEntryTable.id);

        // 若未匹配到已选择的数据表则初始化（可能被移除了）
        if (hasSelectedEntryTable) {
          refreshHash.selectedEntryTable = hasSelectedEntryTable;
          this.handleGetEntryFields(thisSelectedEntryTable.id);
        } else {
          refreshHash.selectedEntryTable = {
            ...initSelectedEntryTable
          }
          refreshHash.entryFields = [];
        }

      }

      this.setState({...refreshHash});
    }

  }

  // 选中数据表
  handleSelectEntryTable = async (entryTable: any) => {
    let { id } = this.state.selectedEntryTable;
    if (entryTable && entryTable.id !== id) {
      this.setState({ selectedEntryTable: entryTable });
      this.handleGetEntryFields(entryTable.id);
    }
  }

  // 获取数据表字段
  handleGetEntryFields = async (entryTableId: any) => {

    let currentEntryTableId = entryTableId || this.state.selectedEntryTable.id;

    this.setState({ loading: true });

    let { errcode, data }: any = await api.getEntryTableEntryFields(currentEntryTableId);

    if (errcode >= 0) {
      this.setState({
        entryFields: data.entryTable.EntryFields,
        visible: false,
        loading: false,
        subModalTitle: null,
        subModalComponent: null,
      })
    }

  }

  // 数据表查询
  handleOnChangeTableName = (e: any) => {

    const { orginEntryTables } = this.state;
    const { value } = e.target;

    let entryTables = [];

    if (value) {

      entryTables = orginEntryTables.filter((o: any) => {

        let orginName = o.name.toLowerCase();
        let orginDescribe = o.describe.toLowerCase();
        let searchName = value.toLowerCase();
        return orginName.indexOf(searchName) >= 0 || orginDescribe.indexOf(searchName) >= 0;

      });

    } else {

      entryTables = orginEntryTables;

    }

    this.setState({ entryTables });

  }

  // 创建新版本
  handleOpenDrawerWindow = async (type: string, record?: any) => {

    let { selectedEntryTable } = this.state;
    let application = global.G_LOCALSTORAGE.get('_SELECT_APP');

    if (type === 'createEntryTable') {
      this.setState({
        visible: true,
        subModalTitle: '新建数据表',
        subModalComponent: <CreateEntryTableUnit onCallbackParent={this.handleGetEntryTables} />,
      })
    } else if (type === 'updateEntryTable') {
      this.setState({
        visible: true,
        subModalTitle: '更新数据表',
        subModalComponent: <UpdateEntryTableUnit entryTable={record} onCallbackParent={this.handleGetEntryTables} />,
      })
    } else if (type === 'createEntryField') {
      this.setState({
        visible: true,
        subModalTitle: '创建数据表字段',
        subModalComponent: <CreateEntryFieldUnit entryTable={selectedEntryTable} onCallbackParent={this.handleGetEntryFields} />,
      })
    } else if (type === 'updateEntryField') {
      this.setState({
        visible: true,
        subModalTitle: '更新数据表字段',
        subModalComponent: <UpdateEntryFieldUnit entryField={record} onCallbackParent={this.handleGetEntryFields} />,
      })
    } else if (type === 'createBaseline') {
      this.setState({
        visible: true,
        subModalTitle: '创建基线版本',
        subModalComponent: <BaselineDictionaryUnit application={application} isCreate={true} onCallbackParent={this.handleGetEntryFields} />,
      })
    } else if (type === 'showBaselines') {
      this.setState({
        visible: true,
        subModalTitle: '历史基线版本',
        subModalComponent: <BaselineDictionaryUnit application={application} onCallbackParent={this.handleGetEntryFields} />,
      })
    } else {
      this.setState({
        visible: false,
        subModalTitle: null,
        subModalComponent: null,
      })
    }

  }

  componentWillUnmount() {
    window.onscroll = null;
    this.setState = (state, callback) => {
      return;
    };
  }

  // 扩展菜单
  handleMoreMenuClick = (e: any) => {

    let { key } = e;

    if (key === 'showShareEntryTable') {

      let thisPageHost = window.location.origin;

      let shareUrl = `${thisPageHost}/s/applications/${this.state.selectedApplicationUUID}/entry-tables`;

      let clipboard = new clipboardJS('#copy-share-url', {
        text: () => {
          return shareUrl
        }
      });

      message.success('分享链接复制成功！', 1);

      clipboard.on('success', (e) => {
        e.clearSelection();
      });

      clipboard.on('error', (e) => {
        console.error(e);
      });
      
    } else {
      this.handleOpenDrawerWindow(key);
    }

  }

  render() {
    const state = this.state;
    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 62,
        render: (text: any, record: any, index: number) => index + 1,
      },
      {
        title: '字段名',
        dataIndex: 'fieldName_us',
        key: 'fieldName_us',
        width: 150,
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '中文名',
        dataIndex: 'fieldName_zh',
        key: 'fieldName_zh',
        width: 150,
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        width: 100,
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '缺省值',
        dataIndex: 'initValue',
        key: 'initValue',
        width: 100,
        render: (text: any) => <span style={{whiteSpace: "pre-line"}}>{text || 'NULL'}</span>,
      },
      {
        title: '解析',
        dataIndex: 'analysis',
        key: 'analysis',
        render: (text: any) => <span style={{whiteSpace: "pre-line"}}>{text || '-'}</span>,
      },
      {
        title: '备注',
        dataIndex: 'remark',
        key: 'remark',
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '操作',
        dataIndex: 'options',
        key: 'options',
        width: 80,
        render: (text: any, record: any) => (
          <div>
            <Button danger type="link" size="small" onClick={this.handleOpenDrawerWindow.bind(this, 'updateEntryField', record)}>编辑</Button>
          </div>
        )
      },
    ];

    const moreMenuDOMs = (
      <Menu onClick={this.handleMoreMenuClick} style={{minWidth: 250, textAlign: 'center'}}>
        <Menu.Item key="createBaseline">
          创建基线版本
        </Menu.Item>
        <Menu.Item key="showBaselines">
          查看历史版本
        </Menu.Item>
        <Menu.Item key="showShareEntryTable">
          <span id="copy-share-url">复制分享链接</span>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className="d-gateway">
        <div className="d-gateway-left">
          <div className="d-dic-menu">
            <div className="d-dic-search">
              <Input
                placeholder={`输入表名模糊搜索，当前共${state.orginEntryTables.length}个表`}
                allowClear
                onChange={this.handleOnChangeTableName}
              />
            </div>
            <div className="d-dic-fileds">

              {
                !state.tableLoading && state.entryTables.length === 0 && (
                  <WarningUnit data="暂无数据" style={{paddingTop: '15%'}} />
                )
              }

              {
                state.tableLoading && (
                  <WarningUnit mode="loading" data="数据加载中..." />
                )
              }

              <ul>
                {
                  state.entryTables.map((entryTable: any) => {
                    return (
                      <li
                        key={entryTable.id}
                        className={entryTable.id === state.selectedEntryTable.id ? 'd-field-selected' : ''}
                        onClick={this.handleSelectEntryTable.bind(this, entryTable)}
                      >
                        {entryTable.name} · {entryTable.describe}
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
        </div>
        <div className="d-gateway-right">
          <div className="d-greeting">
            <div className="d-greeting-left">
              {
                state.selectedEntryTable.id ? (
                  <span>
                    数据表：{ state.selectedEntryTable.name || '-' }
                    <span style={{fontSize: 16}}>（{ state.selectedEntryTable.describe || '-' }）</span>
                    <span onClick={this.handleOpenDrawerWindow.bind(this, 'updateEntryTable', state.selectedEntryTable)} className="d-edit"><EditOutlined /></span>
                  </span>
                )
                :
                  (
                    <span className="d-text-gray">请先选择左侧数据表</span>
                  )
              }
            </div>
            <div className="d-greeting-right">
              <Button
                type="primary"
                style={{marginLeft: 5}}
                disabled={!state.selectedEntryTable.id}
                onClick={this.handleOpenDrawerWindow.bind(this, 'createEntryField')}
              >
                添加字段
              </Button>
              <Button
                type="primary"
                style={{marginLeft: 5}}
                disabled={!state.selectedApplicationUUID}
                onClick={this.handleOpenDrawerWindow.bind(this, 'createEntryTable')}
              >
                创建数据表
              </Button>
              <Dropdown overlay={moreMenuDOMs} placement="bottomRight">
                <Button icon={<DashOutlined />} style={{marginLeft: 5}} />
              </Dropdown>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={state.entryFields}
            rowKey={record => record.id}
            loading={state.loading}
            bordered
            pagination={false}
            rowClassName={
              (record) => {

                let classNameStr = '';

                // if (record.status === 'published') classNameStr = 'd-bg-light-success';
                if (['inactive', 'stoped', 'deleted'].includes(record.status)) classNameStr = 'd-bg-gray';

                return classNameStr;
              }
            }
            scroll={{ y: state.contentHeight }}
          />
        </div>
        <DrawerUnit
          title={state.subModalTitle}
          isVisible={state.visible}
          width="60%"
          callbackParent={this.handleOpenDrawerWindow.bind(this, 'close')}
        >
          { state.subModalComponent }
        </DrawerUnit>
      </div>
    );
  }
}

export default LayoutHOC(Home, <div />);
