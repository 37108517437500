import React, { useEffect } from 'react';
import LayoutHOC from '../../components/layout';

const Home = (props: any) => {

  useEffect(() => {
  }, []);

  return (
    <div style={{width: '100%', textAlign: 'center'}}>
      <img src="https://docs.cq-tct.com/fpm/construction_doing.png" alt="" draggable="false" style={{width: '60%'}} />
      <div>系统正在持续完善中...</div>
    </div>
  );
};

export default LayoutHOC(Home);
