/*
 * @Descripttion: 接口集中配置
 * @Author: qingzi.wang
 * @Date: 2021-03-08 09:25:21
 */
import axios from './request';

//（示例）

const getDemo = async () => {
  return axios(`/`, 'GET', null);
};

/* 公共 */

const login = async (datas: object) => { // 登录
  return axios(`/auth/login`, 'POST', datas);
};
const getMineInfo = async () => { // 获取自己信息（含应用和权限）
  return axios(`/api/mine`, 'GET');
};
const changePassword = async (datas: any) => { // 修改自己的密码
  return axios(`/api/change-self-password`, 'PUT', datas);
};

const getQiniuToken = async () => { // 获取七牛token
  return axios(`/api/qiniu/token`, 'GET', null);
};

/* 权限（应该是系统配置） */

const getAuths = async () => { // 获取所有权限
  return axios(`/api/auths`, 'GET', null);
};
const createAuth = async (datas: any) => { // 创建权限信息
  return axios(`/api/auth`, 'POST', datas);
};
const updateAuth = async (id: string, datas: any) => { // 更新权限信息
  return axios(`/api/auths/${id}`, 'PUT', datas);
};

/* 人员 */

const getUsers = async () => { // 获取所有人员
  return axios(`/api/users`, 'GET', null);
};
const createUser = async (datas: any) => { // 创建人员信息
  return axios(`/api/user`, 'POST', datas);
};
const updateUser = async (uuid: string, datas: any) => { // 更新人员信息
  return axios(`/api/users/${uuid}`, 'PUT', datas);
};

/* 分组 */

const getGroups = async () => { // 获取所有分组
  return axios(`/api/groups`, 'GET', null);
};
const getGroupDetail = async (uuid: string) => { // 获取指定分组详情
  return axios(`/api/groups/${uuid}`, 'GET', null);
};
const createGroup = async (datas: any) => { // 创建分组信息
  return axios(`/api/group`, 'POST', datas);
};
const updateGroup = async (uuid: string, datas: any) => { // 更新分组信息
  return axios(`/api/groups/${uuid}`, 'PUT', datas);
};
const assignGroupPower = async (uuid: string, datas: any) => { // 分组分配授权
  return axios(`/api/groups/${uuid}/assign-power`, 'POST', datas);
};

/* 应用 */

const getApplications = async () => { // 获取所有应用
  return axios(`/api/applications`, 'GET', null);
};
const createApplication = async (datas: any) => { // 创建应用
  return axios(`/api/application`, 'POST', datas);
};
const updateApplication = async (uuid: string, datas: any) => { // 更新应用
  return axios(`/api/applications/${uuid}`, 'PUT', datas);
};
const getApplicationRedemptionCodeStatistics = async (uuid: string) => { // 应用下兑换码使用情况
  return axios(`/api/applications/${uuid}/redemption-code-statistics`, 'GET', null);
};

/* 安装包版本 */

const getApplicationVersions = async (uuid: string) => { // 获取应用版本
  return axios(`/api/applications/${uuid}/versions`, 'GET', null);
};

const getApplicationLastVersion = async (uuid: string, queryStr: string) => { // 获取应用下最新版本
  let baseUrl = `/api/applications/${uuid}/last-version`;

  if (queryStr) baseUrl += queryStr

  return axios(baseUrl, 'GET', null);
};

const createApplicationVersion = async (uuid: string, datas: any) => { // 创建应用版本
  return axios(`/api/applications/${uuid}/version`, 'POST', datas);
};

const getVersion = async (uuid: string) => { // 获取指定版本
  return axios(`/api/versions/${uuid}`, 'GET', null);
};

const updateVersion = async (uuid: string, datas: any) => { // 更新应用版本
  return axios(`/api/versions/${uuid}`, 'PUT', datas);
};

/* 数据字典 */

const createEntryTable = async (uuid: string, datas: any) => { // 创建数据表
  return axios(`/api/applications/${uuid}/entry-table/create`, 'POST', datas);
};
const getApplicationEntryTables = async (uuid: string) => { // 获取指定应用下所有数据表
  return axios(`/api/applications/${uuid}/entry-tables`, 'GET', null);
};
const updateEntryTable = async (entryTableId: string, datas: any) => { // 更新指定数据表记录
  return axios(`/api/entry-tables/${entryTableId}`, 'PUT', datas);
};
const deleteEntryTable = async (uuid: string, entryTableId: string) => { // 删除指定数据表记录
  return axios(`/api/applications/${uuid}/entry-tables/${entryTableId}`, 'DELETE');
};

const createEntryField = async (entryTableId: string, datas: any) => { // 创建数据表字段
  return axios(`/api/entry-tables/${entryTableId}/entry-field/create`, 'POST', datas);
};
const getEntryTableEntryFields = async (entryTableId: string) => { // 获取指定数据表下的字段
  return axios(`/api/entry-tables/${entryTableId}/entry-fields`, 'GET', null);
};
const updateEntryField = async (entryFieldId: string, datas: any) => { // 更新指定字段记录
  return axios(`/api/entry-fields/${entryFieldId}`, 'PUT', datas);
};
const deleteEntryField = async (entryTableId: string, entryFieldId: string) => { // 删除指定字段记录
  return axios(`/api/entry-tables/${entryTableId}/entry-fields/${entryFieldId}`, 'DELETE');
};
const uploadEntryTablesAndEntryFields = async (uuid: string, datas: any) => { // 上传数据表和字段（直传）
  return axios(`/api/upload/applications/${uuid}/entry-table-and-entry-fields`, 'POST', datas);
};
const uploadEntryTableAndEntryFields = async (entryTableId: string, datas: any) => { // 指定数据表上传字段（直传）
  return axios(`/api/upload/entry-tables/${entryTableId}/entry-fields`, 'POST', datas);
};

const SHARE_getApplicationEntryTables = async (uuid: string) => { // 获取指定应用下所有数据表（对外分享）
  return axios(`/share/api/applications/${uuid}/entry-tables`, 'GET', null);
};
const SHARE_getEntryTableEntryFields = async (entryTableId: string) => { // 获取指定数据表下的字段（对外分享）
  return axios(`/share/api/entry-tables/${entryTableId}/entry-fields`, 'GET', null);
};

// 数据字典基线
const createBaselineDictionaryVersion = async (uuid: string, datas: any) => { // 创建基线版本
  return axios(`/api/applications/${uuid}/baseline-dictionary-version/create`, 'POST', datas);
};
const getBaselineDictionaryVersion = async (uuid: string) => { // 获取应用基线版本信息
  return axios(`/api/baseline-dictionary-versions/${uuid}`, 'GET', null);
};
const getBaselineDictionaryVersions = async (uuid: string) => { // 获取应用基线版本
  return axios(`/api/applications/${uuid}/baseline-dictionary-versions`, 'GET', null);
};
const getEntryTablesOfBaselineDictionaryVersion = async (uuid: string) => { // 获取应用基线版本数据表
  return axios(`/api/baseline-dictionary-versions/${uuid}/entry-tables`, 'GET', null);
};
const getEntryFieldsOfBaselineEntryTable = async (uuid: string, tableId: string|number) => { // 获取应用基线版本数据表下字段
  return axios(`/api/baseline-dictionary-versions/${uuid}/entry-tables/${tableId}/entry-fields`, 'GET', null);
};
const SHARE_getEntryTablesOfBaselineDictionaryVersion = async (uuid: string) => { // 获取应用基线版本数据表
  return axios(`/share/api/baseline-dictionary-versions/${uuid}/entry-tables`, 'GET', null);
};
const SHARE_getEntryFieldsOfBaselineEntryTable = async (uuid: string, tableId: string|number) => { // 获取应用基线版本数据表下字段
  return axios(`/share/api/baseline-dictionary-versions/${uuid}/entry-tables/${tableId}/entry-fields`, 'GET', null);
};

// Office加载项
const createOfficeAddIn = async (uuid: string, datas: any) => { // 创建Office加载项记录
  return axios(`/api/applications/${uuid}/office-add-ins`, 'POST', datas);
};
const getOfficeAddIns = async (uuid: string) => { // 获取所有Office加载项记录
  return axios(`/api/applications/${uuid}/office-add-ins`, 'GET', null);
};
const getLastOfficeAddIns = async (uuid: string) => { // 获取最新Office加载项记录
  return axios(`/api/applications/${uuid}/office-add-ins/last`, 'GET', null);
};
const updateOfficeAddIn = async (uuid: string, datas: any) => { // 更新Office加载项记录
  return axios(`/api/office-add-ins/${uuid}`, 'PUT', datas);
};
const getOfficeAddIn = async (uuid: string) => { // 获取Office加载项记录详情
  return axios(`/api/office-add-ins/${uuid}`, 'GET', null);
};

// 错误日志
const getApplicationErrorLogs = async (uuid: string, queryStr: string) => { // 获取应用错误日志
  let baseUrl = `/api/applications/${uuid}/error-logs`;
  if (queryStr) baseUrl += queryStr
  return axios(baseUrl, 'GET', null);
};
const getErrorLog = async (id: string) => { // 获取错误日志详情
  return axios(`/api/error-logs/${id}`, 'GET', null);
};

// eslint-disable-next-line
export default {
  login,
  getMineInfo,
  changePassword,
  getQiniuToken,
  getUsers,
  createUser,
  updateUser,
  getGroups,
  getGroupDetail,
  createGroup,
  updateGroup,
  assignGroupPower,
  getAuths,
  createAuth,
  updateAuth,

  getApplications,
  createApplication,
  updateApplication,

  getApplicationRedemptionCodeStatistics,
  getApplicationVersions,
  getApplicationLastVersion,
  createApplicationVersion,
  updateVersion,
  getVersion,

  getApplicationEntryTables,
  createEntryTable,
  updateEntryTable,
  deleteEntryTable,
  getEntryTableEntryFields,
  createEntryField,
  updateEntryField,
  deleteEntryField,
  uploadEntryTablesAndEntryFields,
  uploadEntryTableAndEntryFields,
  createBaselineDictionaryVersion,
  getBaselineDictionaryVersion,
  getBaselineDictionaryVersions,
  getEntryTablesOfBaselineDictionaryVersion,
  getEntryFieldsOfBaselineEntryTable,

  createOfficeAddIn,
  getOfficeAddIns,
  getLastOfficeAddIns,
  updateOfficeAddIn,
  getOfficeAddIn,

  getApplicationErrorLogs,
  getErrorLog,

  SHARE_getApplicationEntryTables,
  SHARE_getEntryTableEntryFields,
  SHARE_getEntryTablesOfBaselineDictionaryVersion,
  SHARE_getEntryFieldsOfBaselineEntryTable,

  getDemo
};