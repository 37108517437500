/*
 * @Description: 应用配置
 * @Author: qingzi.wang
 * @Date: 2023-05-30 10:46:54
 * @LastEditTime: 2023-05-30 11:16:11
 */
import React from 'react';
import BasicInfoUnit from './basicInfo';
import UploadRedemptionCodeUnit from './uploadRedemptionCode';

const Configure = (props: any) => {
  const { application, onCallbackParent } = props;
  return (
    <div style={{padding: '5px 15px'}}>
      <BasicInfoUnit application={application} onCallbackParent={onCallbackParent} />
      <UploadRedemptionCodeUnit application={application} onCallbackParent={onCallbackParent} />
    </div>
  );
};

export default Configure;
