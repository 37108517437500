import React, { Component } from 'react';
import {
  Table,
  Button,
  Tag
} from 'antd';

import DrawerUnit from '../../../components/drawer';
import CreateAuthUnit from './create';
import UpdateAuthUnit from './update';
import api from '../../../services/api';

declare let global: any;

export interface IStates {
  loading: boolean,
  auths: any,
  visible: boolean,
  subModalTitle: any,
  subModalComponent: any,
}

class AuthUnit extends Component<any, IStates> {

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      auths: [],
      visible: false,
      subModalTitle: null,
      subModalComponent: null,
    }
  }

  componentDidMount() {
    this.handleGetAuths();
  }

  // 获取所有权限
  handleGetAuths = async () => {
    let { errcode, data }: any = await api.getAuths();

    if (errcode >= 0) {
      this.setState({
        auths: data.auths
      });

      this.handleOpenDrawerWindow();
    }

  }

  handleOpenDrawerWindow = (type?: string, record?: any) => {
    if (type === 'createAuth') {
      this.setState({
        visible: true,
        subModalTitle: '新建权限',
        subModalComponent: <CreateAuthUnit onCallbackParent={this.handleGetAuths} />,
      })
    } else if (type === 'updateAuth') {
      this.setState({
        visible: true,
        subModalTitle: '更新权限',
        subModalComponent: <UpdateAuthUnit auth={record} onCallbackParent={this.handleGetAuths} />,
      })
    } else {
      this.setState({
        visible: false,
        subModalTitle: null,
        subModalComponent: null,
      })
    }
  }

  render() {
    const state = this.state;
    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 62,
        render: (text: any, record: any, index: number) => index + 1,
      },
      {
        title: '编码',
        dataIndex: 'name',
        key: 'name',
        width: 100,
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '名称',
        dataIndex: 'describe',
        key: 'describe',
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        width: 80,
        filters: [
          {
            text: '正常',
            value: 'normal',
          },
          {
            text: '未激活',
            value: 'inactive',
          },
          {
            text: '已删除',
            value: 'deleted',
          },
        ],
        onFilter: (value: any, record: any) => record.status.indexOf(value) === 0,
        render: (text: any) => {

          let thisStatus = global.G_CONFIG.enum.normalStatus.find((o:any) => o.value === text);

          if (thisStatus) {
            return <Tag color={thisStatus.tagColor}>{thisStatus.text}</Tag>
          } else {
            return '-';
          }

        }
      },
      {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        render: (text: any) => {
          if (text === 'preset') {
            return '普通预置';
          } else if (text === 'preset-admin') {
            return '管理预置';
          } else {
            return '普通';
          }
        }
      },
      {
        title: '创建时间',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: any) => <span>{global.G_DATE_FORMAT(text, 'fullTimes')}</span>,
      },
      {
        title: '操作',
        dataIndex: 'options',
        key: 'options',
        width: 80,
        render: (text: any, record: any) => {
          let { type } = record;
          if (['preset', 'preset-admin'].includes(type)) {
            return '-'
          } else {
            return <Button danger type="link" size="small" onClick={this.handleOpenDrawerWindow.bind(this, 'updateAuth', record)}>编辑</Button>
          }
        }
      },
    ];

    return (
      <div>
        <div className="d-greeting">
          <div className="d-greeting-left"></div>
          <div className="d-greeting-right">
            <Button
              type="primary"
              style={{marginLeft: 5}}
              onClick={this.handleOpenDrawerWindow.bind(this, 'createAuth')}
            >
              新增权限
            </Button>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={state.auths}
          rowKey={record => record.id}
          loading={state.loading}
          bordered
          size='middle'
          pagination={false}
          rowClassName={
            (record) => {
              return ['inactive', 'stoped', 'deleted'].includes(record.status) ? 'd-bg-gray' : '';
            }
          }
        />
        <DrawerUnit
          title={state.subModalTitle}
          isVisible={state.visible}
          width="60%"
          callbackParent={this.handleOpenDrawerWindow.bind(this, 'close')}
        >
          { state.subModalComponent }
        </DrawerUnit>
      </div>
    )
  }
}

export default AuthUnit;