import React, { useState } from 'react';
import {
  Spin,
  Form,
  Input,
  Button,
  message,
} from 'antd';
import api from '../../../services/api';

const formItemLayout = {
  labelCol: {
    sm: { span: 6 }
  },
  wrapperCol: {
    sm: { span: 14 }
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      span: 24
    },
  },
};

const CreateUserUnit = (props: any) => {

  const { onCallbackParent } = props;

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  // 提交表单
  const handleSubmit = async (values: any) => {

    let datas = {
      ...values,
    };

    setIsLoading(true);
    let res: any = await api.createUser(datas);
    setIsLoading(false);

    if (res.errcode >= 0) {

      message.success('新增人员成功！');

      if (onCallbackParent) {
        onCallbackParent();
      }
    }

  };

  return (
    <div style={{padding: 15}}>
      <Spin spinning={isLoading}>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={handleSubmit}
          scrollToFirstError
        >
          <Form.Item
            name="name"
            label="姓名"
            rules={[{ required: true, message: '请输入姓名！' }]}
          >
            <Input placeholder="请输入姓名！" />
          </Form.Item>

          <Form.Item
            name="mobile"
            label="手机号"
            rules={[{ required: true, message: '请输入手机号！' }]}
          >
            <Input placeholder="请输入手机号！" />
          </Form.Item>

          <Form.Item
            name="email"
            label="邮箱"
            rules={[{ required: true, message: '请输入邮箱！' }]}
          >
            <Input placeholder="请输入邮箱！" />
          </Form.Item>

          <Form.Item
            name="jobNumber"
            label="工号"
          >
            <Input placeholder="请输入工号！" />
          </Form.Item>

          <Form.Item
            name="describe"
            label="描述"
          >
            <Input.TextArea
              rows={6}
              placeholder={`请输入人员描述！`}
            />
          </Form.Item>

          <Form.Item {...tailFormItemLayout} style={{textAlign: 'center'}}>
            <Button type="primary" htmlType="submit">
              立即提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default CreateUserUnit;
