import React, { useState, useEffect } from 'react';
import {
  Spin,
  Form,
  Input,
  Button,
  message
} from 'antd';

import api from '../../services/api';

const formItemLayout = {
  labelCol: {
    sm: { span: 5 }
  },
  wrapperCol: {
    sm: { span: 16 }
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      span: 24
    },
  },
};

const UpdateOfficeAddIn = (props: any) => {

  const { officeAddIn, onCallbackParent } = props;

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [currentOfficeAddIn, setCurrentOfficeAddIn] = useState<any>({
    uuid: null,
    ...officeAddIn
  }); // 最新加载项信息

  useEffect(() => {
    handleGetCurrentOfficeAddIn();
    setIsLoading(false);
    // eslint-disable-next-line
  }, [])

  // 获取指定加载项信息
  const handleGetCurrentOfficeAddIn = async () => {

    let { uuid } = currentOfficeAddIn;

    let res: any = await api.getOfficeAddIn(uuid);

    if (res.errcode >= 0) {
      setCurrentOfficeAddIn(res.data.officeAddIn);
    }

  }

  // 提交表单
  const handleSubmit = async (values: any) => {

    let datas = {
      ...values
    };

    let { uuid } = currentOfficeAddIn;

    if (!uuid) return;

    setIsLoading(true);

    let res: any = await api.updateOfficeAddIn(uuid, datas);

    setIsLoading(false);

    if (res.errcode >= 0) {

      message.success('Office-AddIns记录更新成功！', 1);

      if (onCallbackParent) {
        onCallbackParent();
      }
    }

  };

  return (
    <div style={{padding: 15}}>
      <Spin spinning={isLoading}>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={handleSubmit}
          initialValues={{
            ...officeAddIn
          }}
          scrollToFirstError
        >
          <Form.Item
            name="version"
            label="版本号"
            rules={[{ required: true, message: '请输入版本号！' }]}
            help={<small>仅包含版本号，不带“V”</small>}
          >
            <Input placeholder="请输入版本号，如：1.0.0" />
          </Form.Item>

          <Form.Item
            name="field001"
            label="字段01"
          >
            <Input.TextArea
              rows={2}
              placeholder={`请输入字段内容`}
            />
          </Form.Item>

          <Form.Item
            name="field002"
            label="字段02"
          >
            <Input.TextArea
              rows={2}
              placeholder={`请输入字段内容`}
            />
          </Form.Item>

          <Form.Item
            name="field003"
            label="字段03"
          >
            <Input.TextArea
              rows={2}
              placeholder={`请输入字段内容`}
            />
          </Form.Item>

          <Form.Item
            name="field004"
            label="字段04"
          >
            <Input.TextArea
              rows={2}
              placeholder={`请输入字段内容`}
            />
          </Form.Item>

          <Form.Item
            name="field005"
            label="字段05"
          >
            <Input.TextArea
              rows={2}
              placeholder={`请输入字段内容`}
            />
          </Form.Item>

          <Form.Item
            name="field006"
            label="字段06"
          >
            <Input.TextArea
              rows={2}
              placeholder={`请输入字段内容`}
            />
          </Form.Item>

          <Form.Item
            name="remark"
            label="备注"
          >
            <Input.TextArea
              rows={4}
              placeholder={`请输入备注（可选）`}
            />
          </Form.Item>

          <Form.Item {...tailFormItemLayout} style={{textAlign: 'center'}}>
            <Button type="primary" htmlType="submit">
              立即提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default UpdateOfficeAddIn;