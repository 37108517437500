/*
 * @Description: 
 * @Author: qingzi.wang
 * @Date: 2022-06-01 16:17:10
 * @LastEditTime: 2022-06-13 21:08:56
 */
import React, { Component } from 'react';
import api from '../../services/api';
import {
  Spin,
  Tag,
  Descriptions,
} from 'antd';
import './common.less';

declare let global: any;

class Home extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      id: this.props.id,
      loading: true,
      errorLog: {
        level: null,
        platform: null,
        status: null,
        content: null,
        triggerTime: null,
        version: null,
        account: null,
        systemInfo: null,
        serialNumber: null,
        url: null,
        remark: null,
        createdAt: null,
        Application: {
          name: null
        }
      }
    }
  }

  componentDidMount() {
    this.handleGetErrorLog();
  }

  handleGetErrorLog = async () => {
    const { id } = this.state;

    let { data }: any = await api.getErrorLog(id);

    this.setState({
      loading: false,
      errorLog: data.errorLog
    })

  }

  render() {
    const { state } = this;
    const { content } = state.errorLog;

    let contentType = typeof content;
    let contentRender = content;

    if (!["string", "number", "bigint", "boolean", "undefined"].includes(contentType)) {
      contentRender = JSON.stringify(content, null, 2)
    }

    return (
      <>
        <Spin spinning={state.loading}>
          <div style={{padding: 15}}>
            <Descriptions column={1} className="d-description">
              <Descriptions.Item label="流水号">{state.errorLog.serialNumber || '/'}</Descriptions.Item>
              <Descriptions.Item label="所属应用">{state.errorLog.Application.name}</Descriptions.Item>
              <Descriptions.Item label="日志等级">{state.errorLog.level || '/'}</Descriptions.Item>
              <Descriptions.Item label="来源平台">{state.errorLog.platform || '/'}</Descriptions.Item>
              <Descriptions.Item label="软件版本">{state.errorLog.version || '/'}</Descriptions.Item>
              <Descriptions.Item label="请求路由">{state.errorLog.url || '/'}</Descriptions.Item>
              <Descriptions.Item label="发生账号">{state.errorLog.account || '-'}</Descriptions.Item>
              <Descriptions.Item label="发生时间">{state.errorLog.triggerTime ? global.G_DATE_FORMAT(state.errorLog.triggerTime, 'fullTimes') : '-'}</Descriptions.Item>
              <Descriptions.Item label="登记时间">{state.errorLog.createdAt ? global.G_DATE_FORMAT(state.errorLog.createdAt, 'fullTimes') : '-'}</Descriptions.Item>
              <Descriptions.Item label="系统信息">
                {
                  state.errorLog.systemInfo ?
                    <div className='d-error-log-remark'>{state.errorLog.systemInfo}</div>
                  :
                    '/'
                }
              </Descriptions.Item>
              <Descriptions.Item label="上报备注">
                {
                  state.errorLog.remark ?
                    <div className='d-error-log-remark'>{state.errorLog.remark}</div>
                  :
                    '/'
                }
              </Descriptions.Item>
              <Descriptions.Item label="上报详情">
                {
                  contentRender ?
                    <div className='d-error-log-remark'>{contentRender}</div>
                  :
                    '/'
                }
              </Descriptions.Item>
            </Descriptions>
          </div>
        </Spin>
      </>
    );
  }

}

export default Home;
