import React, { useEffect, useState } from 'react';
import {
  Upload,
  Button,
  message,
  Tooltip,
} from 'antd';
import { UploadOutlined, BlockOutlined, DeleteOutlined } from '@ant-design/icons';
import ClipboardJS from 'clipboard';
import { setTimeout } from 'timers';

declare let global: any;
let copy: any;
let setTimeoutHandler: any;

const UploadUnit = (props: any) => {

  const { initFileUrl, qiniuToken, showClear, onCallbackParent, onCallbackLoading, onClear } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [randomClassName, setRandomClassName] = useState('');
  const [uploadFileList, setUploadFileList] = useState([]);
  const [uploadFileUrl, setUploadFileUrl] = useState<string | null>(initFileUrl);
  // const [uploadFileSize, setUploadFileSize] = useState<number | null>();

  useEffect(() => {
    setUploadFileUrl(initFileUrl);
  }, [initFileUrl])

  useEffect(() => {
    let thisRandomClassName = `d-clipboard-${`${Math.random()}`.split('.')[1]}`;
    setRandomClassName(thisRandomClassName);
    return () => {
      setTimeoutHandler && clearTimeout(setTimeoutHandler);
    }

  }, [])

  /**
   * 反馈已上传的文件地址
   * @param info 
   */
  const handleOnCallbackParent = (info: any) => {

    let thisUploadFileUrl: string = `${global.G_CONFIG.docHost}/${info.response.key}`;
    let thisUploadFileSize: any = info.size;

    setUploadFileUrl(thisUploadFileUrl);
    // setUploadFileSize(thisUploadFileSize);

    onCallbackParent({
      url: thisUploadFileUrl,
      size: thisUploadFileSize
    });

  }
  /**
   * 文件上传loading
   * @param fileLoading 
   */
  const handleFileLoading = (fileLoading: boolean) => {
    onCallbackLoading(fileLoading);
    setIsLoading(fileLoading);
  }
  /**
   * 复制路径
   */
  const handleClipboard = () => {
    copy = new ClipboardJS(`.${randomClassName}`);
    copy.on('success', (e: any) => {
      message.success('复制成功！');
    });
    copy.on('error', (e: any) => {
      message.success('复制失败！');
    });

    setTimeoutHandler = setTimeout(() => {
      copy.destroy();
    }, 500)

  }
  /**
   * 组装上传请求参数
   */
  const uploadFileProps = {
    name: 'file',
    multiple: false,
    fileList: uploadFileList,
    showUploadList: false,
    action: `https://upload.qiniup.com`,
    onChange(info: any) {
      handleFileLoading(true);
      const { status } = info.file;
      if (status === 'done') {
        handleFileLoading(false);
        message.success(`${info.file.name} 上传成功！`, 1);
        handleOnCallbackParent(info.file);
      } else if (status === 'error') {
        handleFileLoading(false);
        message.error(`${info.file.name} 上传失败！`, 1);
      }
      setUploadFileList(info.fileList);
    },
    beforeUpload(file: any) {
      const isLt100M = file.size / 1024 / 1024 < 100;
      if (!isLt100M) {
        message.error('文件最大不能超过 100MB!', 1);
      }
      return isLt100M;
    },
    data (info: any){
      return {
        token: qiniuToken,
        key: 'funenc/idrs/'+global.G_DATE_FORMAT(new Date(), 'fullTimeToMini') +'_'+ info.name
      };
    }
  };

  // 需要重新赋值fileList，不然onChange里面只能接受一次status

  return (
    <>
      <Upload {...uploadFileProps}>
        <Button loading={isLoading} icon={<UploadOutlined />}>点击上传</Button>
      </Upload>
      {
        uploadFileUrl && (
          <div className='d-file-url'>
            <Tooltip title="点击复制路径">
              <div
                className={`${randomClassName}`}
                style={{cursor: 'pointer'}}
                data-clipboard-text={uploadFileUrl}
                onClick={handleClipboard}
              >
                <BlockOutlined /> {uploadFileUrl}
              </div>
            </Tooltip>
            {/* 移除图标 */
              showClear && (
                <Tooltip title="移除此文件">
                  <Button danger type="text" shape="circle" className="d-file-url-clear" onClick={onClear} icon={<DeleteOutlined />} />
                </Tooltip>
              )
            }
          </div>
        )
      }
    </>
  );
};

export default UploadUnit;
