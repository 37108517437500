import React, { useState, useEffect } from 'react';

import {
  Spin,
  Form,
  Input,
  Select,
  Button,
  message
} from 'antd';

import api from '../../services/api';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    sm: { span: 6 }
  },
  wrapperCol: {
    sm: { span: 14 }
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      span: 24
    },
  },
};

const CreateEntryFieldUnit = (props: any) => {

  const { entryTable, onCallbackParent } = props;

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
  }, [])

  // 提交表单
  const handleSubmit = async (values: any) => {

    let { id: entryTableId } = entryTable;

    let datas = {
      ...values,
    };

    setIsLoading(true);
    let res: any = await api.createEntryField(entryTableId, datas);
    setIsLoading(false);

    if (res.errcode >= 0) {

      message.success('新字段添加成功！');

      if (onCallbackParent) {
        onCallbackParent();
      }
    }

  };

  return (
    <div style={{padding: 15}}>
      <Spin spinning={isLoading}>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={handleSubmit}
          initialValues={{
            status: 'normal'
          }}
          scrollToFirstError
        >
          <Form.Item
            name="fieldName_us"
            label="字段名"
            rules={[{ required: true, message: '请输入字段名！' }]}
          >
            <Input placeholder="请输入字段名，如：name" />
          </Form.Item>

          <Form.Item
            name="fieldName_zh"
            label="字段中文名"
            rules={[{ required: true, message: '请输入字段中文名！' }]}
          >
            <Input placeholder="请输入字段中文名，如：项目名称" />
          </Form.Item>

          <Form.Item
            name="type"
            label="字段类型"
            rules={[{ required: true, message: '请输入字段类型！' }]}
          >
            <Input placeholder="请输入字段类型，如：Varchar(20)" />
          </Form.Item>

          <Form.Item
            name="analysis"
            label="解析说明"
          >
            <Input.TextArea
              rows={6}
              placeholder={`请输入解析说明（可选），如：\nwaitSetBasicInfo 等待设置基础信息；\nwaitSetRoles 等待管理员定角色；\nwaitUploadModule 等待上传IPD初始化文件；`}
            />
          </Form.Item>

          <Form.Item
            name="status"
            label="状态"
            rules={[{ required: true, message: '请选择状态！' }]}
          >
            <Select placeholder="请选择状态">
              <Option value="normal">正常</Option>
              <Option value="inactive">未激活</Option>
              <Option value="stoped">已停用</Option>
              <Option value="deleted">已删除</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="initValue"
            label="缺省值"
          >
            <Input placeholder={`请输入缺省值（可选）`} />
          </Form.Item>

          <Form.Item
            name="remark"
            label="备注"
          >
            <Input.TextArea
              rows={6}
              placeholder={`请输入备注（可选）`}
            />
          </Form.Item>

          <Form.Item {...tailFormItemLayout} style={{textAlign: 'center'}}>
            <Button type="primary" htmlType="submit">
              立即提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default CreateEntryFieldUnit;
