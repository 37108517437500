import React, { Component } from 'react';
import {  AndroidOutlined } from '@ant-design/icons';
import { Table, Tag, Button } from 'antd';
import dayjs from 'dayjs';
import { observer, inject } from 'mobx-react';
import LayoutHOC from '../../components/layout';
import DrawerUnit from '../../components/drawer';
import DetailUnit from './detail';
import api from '../../services/api';
import './common.less';

declare let global: any;

interface filterType {
  page: number,
  size: number,
  total: number,
}
export interface IStates {
  loading: boolean,
  visible: boolean,
  subModalTitle: any,
  subModalComponent: any,
  selectedApplicationUUID: any,
  errorLogs: any[],
  columns: any[],
  filter: filterType,
}
@inject("appStore")
@observer
class Home extends Component<any, IStates> {
  constructor(props: any) {
    super(props);

    // 已选择的应用
    let selectedApplicationUUID = global.G_GET_APP_UUID();

    this.state = {
      loading: false,
      visible: false,
      subModalTitle: null,
      subModalComponent: null,
      selectedApplicationUUID,
      errorLogs: [],
      columns: [],
      filter: {
        page: 1,
        size: 10,
        total: 0,
      }
    }
  }

  componentDidMount() {
    const { selectedApplicationUUID, filter } = this.state;
    this.handleColunms();
    if (selectedApplicationUUID) {
      this.handleGetErrorLogs(selectedApplicationUUID, filter);
    }
  }
  // 表格列
  handleColunms = () => {
    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 62,
        render: (text: any, record: any, index: number) => this.handleTableIndex(index),
      },
      {
        title: '应用',
        dataIndex: 'application',
        key: 'application',
        render: (text: any, record: any) => record.Application.name
      },
      {
        title: '流水号',
        dataIndex: 'serialNumber',
        key: 'serialNumber',
        render: (text: any, record: any) => <span className="d-link" onClick={() => this.handleVisibleModel('detail', record)}>{text || '-'}</span>,
      },
      {
        title: '等级',
        dataIndex: 'level',
        key: 'level',
        width: 100,
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '平台',
        dataIndex: 'platform',
        key: 'platform',
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '版本',
        dataIndex: 'version',
        key: 'version',
        width: 80,
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '账号',
        dataIndex: 'account',
        key: 'account',
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '路由',
        dataIndex: 'url',
        key: 'url',
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '发生时间',
        dataIndex: 'triggerTime',
        key: 'triggerTime',
        render: (text: any) => <span>{text ? global.G_DATE_FORMAT(text, 'fullTimes') : '-'}</span>,
      }
    ];
    this.setState({ columns });
  }
  // 获取所有版本
  handleGetErrorLogs = async (applicationUUID: any, filter: filterType) => {
    const currentApplicationUUID = applicationUUID || this.state.selectedApplicationUUID;
    const { page, size } = filter;

    this.setState({ loading: true });

    let { errcode, data }: any = await api.getApplicationErrorLogs(currentApplicationUUID, `?page=${page}&pageSize=${size}`);

    if (errcode >= 0) {
      this.setState({
        errorLogs: data.errorLogs,
        filter: {
          ...filter,
          total: data.total
        },
        selectedApplicationUUID: currentApplicationUUID,
        visible: false,
        loading: false,
        subModalTitle: null,
        subModalComponent: null,
      })
    }

  }
  // 模态框
  handleVisibleModel = async (type: string, record: any) => {
    if (type === 'detail') {
      this.setState({
        visible: true,
        subModalTitle: '错误日志详情',
        subModalComponent: <DetailUnit id={record.id} />,
      })
    } else {
      this.setState({
        visible: false,
        subModalTitle: null,
        subModalComponent: null,
      })
    }
  }
  // 表格变更
  handleChangeTable = (e: any) => {
    console.log(e);
    const { selectedApplicationUUID, filter } = this.state;
    // 仅不在当前页时请求
    if (e.current !== filter.page || e.pageSize !== filter.size) {
      this.handleGetErrorLogs(selectedApplicationUUID, {
        ...filter,
        page: e.current,
        size: e.pageSize,
      });
    }
  }
  // 表格分页序号处理
  handleTableIndex = (index: number) => {
    let { filter } = this.state;
    return `${(filter.page - 1) * filter.size + (index + 1)}`;
  }
  render() {
    const state = this.state;

    return (
      <div className="d-gateway">
        <div className="d-gateway-right">
          <Table
            columns={state.columns}
            dataSource={state.errorLogs}
            rowKey={record => record.id}
            loading={state.loading}
            bordered
            rowClassName={
              (record) => {
                let classNameStr = '';
                if (['L3', 'l3', '3'].includes(record.status)) classNameStr = 'd-bg-pink';
                return classNameStr;
              }
            }
            onChange={this.handleChangeTable}
            pagination={{ total: state.filter.total }}
            locale={{ emptyText: '暂无错误日志记录' }}
          />
        </div>
        <DrawerUnit
          title={state.subModalTitle}
          isVisible={state.visible}
          width="60%"
          callbackParent={this.handleVisibleModel.bind(this, 'close')}
        >
          { state.subModalComponent }
        </DrawerUnit>
      </div>
    );
  }
}

export default LayoutHOC(Home);
