import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import {
  Spin,
  Form,
  Input,
  Select,
  Button,
  message,
  Alert,
  Switch
} from 'antd';
import UploadUnit from '../common/upload';
import api from '../../services/api';

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    sm: { span: 6 }
  },
  wrapperCol: {
    sm: { span: 14 }
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      span: 24
    },
  },
};

const UpdateVersion = (props: any) => {

  const { appStore, onCallbackParent, version } = props;

  const [form] = Form.useForm();
  const [qiniuToken, setQiniuToken] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isEnabledRedemptionCode, setIsEnabledRedemptionCode] = useState(false); // IOS兑换码是否选择启用

  const initFormHash = {
    androidUrl: version.androidUrl,
    androidSize: version.androidSize,
    iosUrl: version.iosUrl,
    iosSize: version.iosSize,
    iosPlistUrl: version.iosPlistUrl,
    iosPlistSize: version.iosPlistSize,
    isEnabledRedemptionCode: version.isEnabledRedemptionCode
  };

  const [formHash, setFormHash] = useState({...initFormHash});

  const [currentVersion, setCurrentVersion] = useState<any>({
    uuid: null,
    ...version
  }); // 最新版本信息

  useEffect(() => {
    setIsLoading(true);
    handleGetQiniuToken();
    handleGetCurrentVersion();
    setIsLoading(false);
    // eslint-disable-next-line
  }, [])
  /**
   * 获取指定版本信息
   */
  const handleGetCurrentVersion = async () => {
    let { uuid } = currentVersion;
    let res: any = await api.getVersion(uuid);
    if (res.errcode >= 0) {
      setCurrentVersion(res.data.version);
    }
  }
  /**
   * 获取七牛token
   */
  const handleGetQiniuToken = async () => {
    let token = await appStore.handleGetQiniuToken();
    setQiniuToken(token);
  }
  /**
   * 提交表单
   * @param values 
   * @returns 
   */
  const handleSubmit = async (values: any) => {
    let { uuid } = currentVersion;
    let datas = {
      ...currentVersion,
      ...values,
      ...formHash,
    };
    if (!uuid) return message.warning("获取版本记录识别码失败！");
    setIsLoading(true);
    let res: any = await api.updateVersion(uuid, datas);
    setIsLoading(false);
    if (res.errcode >= 0) {
      message.success('版本更新成功！', 1);
      if (onCallbackParent) {
        onCallbackParent();
      }
    }
  };
  /**
   * 处理文件上传反馈
   * @param fileLoading 
   */
  const handleUploadLoading = (fileLoading: boolean) => {
    setIsLoading(fileLoading);
  }
  /**
   * Android包
   * @param info 
   */
  const handleUploadAndroidFile = (info: any) => {
    const { url, size } = info;
    const thisFormHash = {
      ...formHash,
      androidUrl: url,
      androidSize: size,
    }
    setFormHash(thisFormHash);
  }
  /**
   * IOS包
   * @param info 
   */
  const handleUploadIosFile = (info: any) => {
    const { url, size } = info;
    const thisFormHash = {
      ...formHash,
      iosUrl: url,
      iosSize: size,
    }
    setFormHash(thisFormHash);
  }
  /**
   * IOS .plist文件
   * @param info 
   */
  const handleUploadIosPlistFile = (info: any) => {
    const { url, size } = info;
    const thisFormHash = {
      ...formHash,
      iosPlistUrl: url,
      iosPlistSize: size,
    }
    setFormHash(thisFormHash);
  }
  /**
   * IOS兑换码开关
   * @param info 
   */
   const handleChangeRedemptionCodeStatus = (info: any) => {
    const thisFormHash = {
      ...formHash,
      isEnabledRedemptionCode: info,
    }
    setIsEnabledRedemptionCode(info);
    setFormHash(thisFormHash);
  }
  /**
   * 清除指定类型条目项
   * @param type 
   */
  const handleClearFormItem = (type: string) => {
    const thisFormHash = {
      ...formHash
    }
    switch (type) {
      case 'android':
        thisFormHash.androidUrl = null;
        thisFormHash.androidSize = null;
        break;
      case 'ios':
        thisFormHash.iosUrl = null;
        thisFormHash.iosSize = null;
        break;
      case 'iosPlist':
        thisFormHash.iosPlistUrl = null;
        thisFormHash.iosPlistSize = null;
        break;
      default:
        break;
    }
    setFormHash(thisFormHash);
  }
  return (
    <div style={{padding: 15}}>
      <Spin spinning={isLoading}>
        {
          version.status === 'published' && (
            <div style={{marginBottom: 15}}>
              <Alert type="warning" showIcon message="当前为已发布的版本，请谨慎操作！" />
            </div>
          )
        }
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={handleSubmit}
          initialValues={{
            type: 'normal',
            status: 'normal',
            ...currentVersion
          }}
          scrollToFirstError
        >
          <Form.Item
            name="number"
            label="版本号"
            rules={[{ required: true, message: '请输入版本号！' }]}
            help={<small>仅包含版本号，不带“V”</small>}
          >
            <Input placeholder="请输入版本号，如：1.0.1" />
          </Form.Item>
          <Form.Item
            name="type"
            label="更新类型"
            rules={[{ required: true, message: '请选择更新类型！' }]}
          >
            <Select placeholder="请选择更新类型">
              <Option value="normal">一般更新</Option>
              <Option value="force">强制更新</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="status"
            label="更新状态"
            rules={[{ required: true, message: '请选择更新状态！' }]}
          >
            <Select placeholder="请选择更新状态">
              <Option value="normal">待发布</Option>
              <Option value="published">已发布</Option>
              <Option value="deleted">已删除</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="subject"
            label="更新主题"
            rules={[{ required: true, message: '请输入更新主题！' }]}
          >
            <Input placeholder="请输入更新主题" />
          </Form.Item>
          <Form.Item
            name="content"
            label="更新内容"
            rules={[{ required: true, message: '请输入更新内容！' }]}
          >
            <Input.TextArea
              rows={6}
              placeholder={`请输入更新内容，如：\n1、修复已知缺陷；\n2、完成接口请求封装；\n3、新增周计划；`}
            />
          </Form.Item>
          <Form.Item
            label="上传安装包(Android)"
          >
            <>
              <UploadUnit
                showClear
                qiniuToken={qiniuToken}
                initFileUrl={formHash.androidUrl}
                onCallbackParent={handleUploadAndroidFile}
                onCallbackLoading={handleUploadLoading}
                onClear={() => handleClearFormItem('android')}
              />
            </>
          </Form.Item>
          <Form.Item
            name="isEnabledRedemptionCode"
            label="是否启用IOS兑换码"
            valuePropName="checked"
            extra={
              <small>
                {
                  isEnabledRedemptionCode ? '此时IOS上传本地*.ipa、*.plist文件为可选' : '开启后IOS应用内检查更新提供便捷（可不用上传本地*.ipa、*.plist文件），与应用IOS兑换码功能同时开启方可生效'
                }
              </small>
            }
          >
            <Switch checkedChildren="开" unCheckedChildren="关" onChange={handleChangeRedemptionCodeStatus} />
          </Form.Item>
          <Form.Item
            label="上传安装包(IOS)"
          >
            <>
              <UploadUnit
                showClear
                qiniuToken={qiniuToken}
                initFileUrl={formHash.iosUrl}
                onCallbackParent={handleUploadIosFile}
                onCallbackLoading={handleUploadLoading}
                onClear={() => handleClearFormItem('ios')}
              />
            </>
          </Form.Item>
          <Form.Item
            label="上传安装包(IOS .plist文件)"
          >
            <>
              <UploadUnit
                showClear
                qiniuToken={qiniuToken}
                initFileUrl={formHash.iosPlistUrl}
                onCallbackParent={handleUploadIosPlistFile}
                onCallbackLoading={handleUploadLoading}
                onClear={() => handleClearFormItem('iosPlist')}
              />
            </>
          </Form.Item>
          <Form.Item {...tailFormItemLayout} style={{textAlign: 'center'}}>
            <Button type="primary" htmlType="submit">
              立即提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default inject("appStore")(observer(UpdateVersion));
