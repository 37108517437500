/*
 * @Description: 入口文件
 * @Author: qingzi.wang
 * @Date: 2021-03-09 12:06:36
 * @LastEditTime: 2023-05-29 16:44:51
 */
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/app.less';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import Routes from './routes/index';
import './config/global.d';

/**
 * 移除页面加载遮罩
 */
const handleRemoveAppLoadingMask = () => {
  const appLoadingMask = document.getElementById('d-app-loading-mask')
  if (appLoadingMask) {
    appLoadingMask.remove()
  }
}

ReactDOM.render(<ConfigProvider locale={zhCN}><Routes /></ConfigProvider>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
handleRemoveAppLoadingMask();