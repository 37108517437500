import React from 'react';
import LayoutHOC from '../../components/layout';

declare let global: any;

const Home = (props: any) => {

  return (
    <div style={{width: '100%', textAlign: 'center'}}>
      <img src="https://docs.cq-tct.com/fpm/construction_doing.png" alt="" draggable="false" style={{width: '60%'}} />
      <div>欢迎访问{global.G_CONFIG.system.name}</div>
    </div>
  );
};

export default LayoutHOC(Home);