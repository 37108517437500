import React, { useState, Fragment } from 'react';
import {
  Spin,
  Form,
  Input,
  Upload,
  Alert,
  Button,
  message,
  Tabs,
  Tag,
  Modal
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import api from '../../services/api';

declare let global: any;
const { TabPane } = Tabs;

const formItemLayout = {
  labelCol: {
    sm: { span: 6 }
  },
  wrapperCol: {
    sm: { span: 14 }
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      span: 24
    },
  },
};

const CreateEntryTable = (props: any) => {

  const { onCallbackParent } = props;

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  // 提交表单
  const handleSubmit = async (values: any) => {

    let currentApplicationUUID = global.G_GET_APP_UUID();
    let datas = {
      ...values,
    };

    setIsLoading(true);
    let res: any = await api.createEntryTable(currentApplicationUUID, datas);
    setIsLoading(false);

    if (res.errcode >= 0) {

      message.success('数据表添加成功！');

      if (onCallbackParent) {
        onCallbackParent(currentApplicationUUID);
      }
    }

  };

  // 上传传成功
  const handleOnCallbackParent = (info: any) => {

    setIsLoading(false);

    let { errcode, data } = info.file.response;

    if (errcode >= 0) {

      let { errorRepeatTables = [], errorSheets = [] } = data;

      if (errorRepeatTables.length > 0 || errorSheets.length > 0) {
        Modal.warning({
          title: '温馨提示',
          width: 600,
          content: (
            <Fragment>
              <div style={{color: 'orange', fontSize: 16, marginBottom: 10}}>当前上传的文件存在瑕疵，请严格按照模板文件上传！</div>
              {
                errorRepeatTables.length > 0 && (
                  <div style={{margin: '5px 0'}}>
                    <span>存在重复的数据表有：</span>
                    {
                      errorRepeatTables.map((text: string, index: number) => {
                        return <Tag key={index} color="volcano">{text}</Tag>
                      })
                    }
                  </div>
                )
              }
              {
                errorSheets.length > 0 && (
                  <div style={{margin: '5px 0'}}>
                    <span>存在错误的Sheet有：</span>
                    {
                      errorSheets.map((text: string, index: number) => {
                        return <Tag key={index} color="volcano">{text}</Tag>
                      })
                    }
                  </div>
                )
              }
            </Fragment>
          ),
        });

        onCallbackParent(null, true); // 通知父组件

      } else if (onCallbackParent) {
        onCallbackParent(); // 通知父组件
      }

    }

  }

  let selectedApplicationUUID = global.G_GET_APP_UUID();
  let token = global.G_LOCALSTORAGE.get('_TOKEN');

  const uploadProps = {
    name: 'file',
    multiple: false,
    action: `${global.G_CONFIG.serverHost}/api/upload/applications/${selectedApplicationUUID}/entry-table-and-entry-fields`,
    headers: {
      authorization: 'Bearer ' + token,
    },
    onChange(info: any) {

      const { status } = info.file;

      if (status === 'done') {
        message.success(`${info.file.name} 上传成功！`, 1);
        handleOnCallbackParent(info);
      } else if (status === 'error') {
        setIsLoading(false);
        message.error(`${info.file.name} 上传失败！`, 1);
      }

    },
    beforeUpload(file: any) {

      setIsLoading(true);

      const isLt100M = file.size / 1024 / 1024 < 1;
      let isStop = true;

      if (!isLt100M) {
        message.error('上传文件应小于1MB！', 1);
        setIsLoading(false);
        isStop = false;
      }

      let fileName = file.name || '';
      let fileExt = fileName.substring(fileName.lastIndexOf("."), fileName.length).toLowerCase();

      if (!['.xls', '.xlsx'].includes(fileExt)) {
        message.error('请上传限定格式文件：.xls或.xlsx', 1);
        setIsLoading(false);
        isStop = false;
      }

      return isStop;

    },
  };

  return (
    <div style={{padding: 15}}>
      <Spin spinning={isLoading}>
        <Alert type="warning" showIcon message="根据不同方式进行数据表创建" />
        <Tabs defaultActiveKey="1">
          <TabPane tab="模板上传" key="1">
            <>

              <div style={{marginBottom: 15}}>
                包含数据表及其字段，请根据提供的模板填入正确的内容，点击<a href={global.G_CONFIG.uploadTemplateUrl.down} target="_blank" rel="noreferrer" download="数据表上传模板">下载模板</a>。
              </div>

              <div style={{marginBottom: 15}}>
                <div style={{textAlign: 'center'}}>
                  <img src="https://docs.cq-tct.com/funenc/fpm/upload-template-20210605_573.png" alt="" width="70%" />
                  <div style={{margin: '15px 0', color: 'gray'}}>（↑样例）</div>
                </div>
              </div>

              <div style={{padding: '0 15%'}}>
                <Upload.Dragger {...uploadProps} disabled={isLoading}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">点击或拖拽文件至此区域上传</p>
                  <p className="ant-upload-hint">仅支持xlsx格式</p>
                </Upload.Dragger>
              </div>
            </>
          </TabPane>
          <TabPane tab="手动录入" key="2">
            <Form
              {...formItemLayout}
              form={form}
              name="register"
              onFinish={handleSubmit}
              scrollToFirstError
            >
              <Form.Item
                name="name"
                label="表名"
                rules={[{ required: true, message: '请输入表名！' }]}
              >
                <Input placeholder="请输入表名，如：Projects" />
              </Form.Item>

              <Form.Item
                name="describe"
                label="表备注"
                rules={[{ required: true, message: '请输入表备注！' }]}
              >
                <Input placeholder="请输入表备注，如：项目表" />
              </Form.Item>

              <Form.Item {...tailFormItemLayout} style={{textAlign: 'center'}}>
                <Button type="primary" htmlType="submit">
                  立即提交
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </Spin>
    </div>
  );
};

export default CreateEntryTable;
