/*
 * @Description: 应用基础信息配置
 * @Author: qingzi.wang
 * @Date: 2023-05-30 10:46:54
 * @LastEditTime: 2023-05-30 17:51:46
 */
import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import {
  Spin,
  Form,
  message,
  Button,
  Input,
  Switch,
} from 'antd';
import api from '../../../services/api';
import UploadUnit from '../../common/upload';

const formItemLayout = {
  labelCol: {
    sm: { span: 6 }
  },
  wrapperCol: {
    sm: { span: 14 }
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      span: 24
    },
  },
};
const initFormHash = {
  isEnabledRedemptionCode: false,
  fullSizeLogoUrl: null,
  bundleId: null,
  title: null,
  describe: null
};
const initRedemptionCodeStatistic = {
  used: 0,
  unused: 0,
  other: 0
};

const BasicInfo = (props: any) => {

  const { application, onCallbackParent, appStore } = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [qiniuToken, setQiniuToken] = useState();
  const [formHash, setFormHash] = useState({...initFormHash});
  const [redemptionCodeStatistic, setRedemptionCodeStatistic] = useState({...initRedemptionCodeStatistic});

  useEffect(() => {
    handleGetQiniuToken();
    handleGetRedemptionCodeStatistic(application.uuid);
  }, [application.uuid])
  /**
   * 获取兑换码使用情况
   * @param applicationUUID 
   */
  const handleGetRedemptionCodeStatistic = async (applicationUUID: string) => {
    let { errcode, data }: any = await api.getApplicationRedemptionCodeStatistics(applicationUUID);
    if (errcode >= 0) {
      setRedemptionCodeStatistic(data)
    }
  }
  /**
   * 获取七牛token
   */
  const handleGetQiniuToken = async () => {
    let token = await appStore.handleGetQiniuToken();
    setQiniuToken(token);
    setIsLoading(false);
  }
  /**
   * 处理文件上传反馈
   * @param fileLoading 
   */
  const handleUploadLoading = (fileLoading: boolean) => {
    setIsLoading(fileLoading);
  }
  /**
   * logo文件上传
   * @param info 
   */
  const handleUploadLogoFile = (info: any) => {
    const { url } = info;
    const thisFormHash = {
      ...formHash,
      fullSizeLogoUrl: url,
    }
    setFormHash(thisFormHash);
  }
  /**
   * 提交表单
   * @param values 
   */
  const handleSubmit = async (values: any) => {
    let datas = { ...formHash, ...values };
    setIsLoading(true);
    let res: any = await api.updateApplication(application.uuid, datas);
    setIsLoading(false);
    if (res.errcode >= 0) {
      message.success('配置处理成功');
      if (onCallbackParent) {
        onCallbackParent();
      }
    }
  };

  return (
    <>
      <div className="d-separator-title">基础配置</div>
      <Spin spinning={isLoading}>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          initialValues={{
            ...application
          }}
          onFinish={handleSubmit}
          scrollToFirstError
        >
          <Form.Item
            name="isEnabledRedemptionCode"
            label="IOS 是否启用兑换码"
            valuePropName="checked"
            extra={<small>已使用：{redemptionCodeStatistic.used}，剩余：{redemptionCodeStatistic.unused}，其他：{redemptionCodeStatistic.other}</small>}
          >
            <Switch checkedChildren="开" unCheckedChildren="关" />
          </Form.Item>
          <Form.Item
            label="IOS 全尺寸logo"
            extra={<small>建议上传1024*1024全尺寸</small>}
          >
            <UploadUnit
              qiniuToken={qiniuToken}
              initFileUrl={application.fullSizeLogoUrl}
              onCallbackParent={handleUploadLogoFile}
              onCallbackLoading={handleUploadLoading}
            />
          </Form.Item>
          <Form.Item
            name="bundleId"
            label="IOS bundleID"
          >
            <Input placeholder="请输入应用安装包bundleID，com.funenc.hello" />
          </Form.Item>
          <Form.Item
            name="title"
            label="IOS 应用名称"
          >
            <Input placeholder="请输入应用安装包名称，如：《王者荣耀》" />
          </Form.Item>
          <Form.Item
            name="describe"
            label="应用备注"
          >
            <Input.TextArea
              rows={6}
              placeholder={`请输入应用描述（选填）`}
            />
          </Form.Item>
          <Form.Item {...tailFormItemLayout} style={{textAlign: 'center'}}>
            <Button type="primary" htmlType="submit">
              立即提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};

export default inject("appStore")(observer(BasicInfo));
