import React, { useState, useEffect } from 'react';
import {
  Spin,
  Form,
  Button,
  message,
  Select,
} from 'antd';
import { observer, inject } from 'mobx-react';
import api from '../../../services/api';

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    sm: { span: 6 }
  },
  wrapperCol: {
    sm: { span: 14 }
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      span: 24
    },
  },
};
const initGroup = {
  id: null,
  name: null,
  userIds: [],
  authIds: [],
  applicationIds: [],
};

const AssignGroupUnit = (props: any) => {

  const { group, onCallbackParent } = props;

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [groupDetail, setGroupDetail] = useState({
    ...initGroup
  });

  const [applications, setApplications] = useState([]);
  const [users, setUsers] = useState([]);
  const [auths, setAuths] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    handleGetGroupDetail();
    handleGetApplications();
    handleGetUsers();
    handleGetAuths();
    setIsLoading(false);

    // eslint-disable-next-line
  }, [])

  const handleGetGroupDetail = async () => {
    let { errcode, data }: any = await api.getGroupDetail(group.id);

    if (errcode >= 0) {
      let { group: resGroup } = data;

      let thisGroup = {
        ...initGroup,
        ...resGroup
      };

      thisGroup.applicationIds = resGroup.Applications.map((o: any) => o.id);
      thisGroup.userIds = resGroup.Users.map((o: any) => o.id);
      thisGroup.authIds = resGroup.Auths.map((o: any) => o.id);

      // 表单异步赋值
      form.setFieldsValue({
        applicationIds: thisGroup.applicationIds,
        userIds: thisGroup.userIds,
        authIds: thisGroup.authIds,
      });

      setGroupDetail(thisGroup);
    }

  }

  const handleGetApplications = async () => {
    let res = await props.appStore.handleGetApplications();
    setApplications(res);
  }

  const handleGetUsers = async () => {
    let res = await props.appStore.handleGetUsers();
    let activeUsers = res.filter((o: any) => o.status === 'normal');
    
    setUsers(activeUsers);
  }

  const handleGetAuths = async () => {
    let res = await props.appStore.handleGetAuths();
    setAuths(res);
  }

  // 提交表单
  const handleSubmit = async (values: any) => {

    let { id } = group;
    let datas = {
      ...values,
    };

    if (!id) return message.warning('获取分组信息失败！', 1);

    setIsLoading(true);
    let res: any = await api.assignGroupPower(id, datas);
    setIsLoading(false);

    if (res.errcode >= 0) {

      message.success('授权处理成功！');

      if (onCallbackParent) {
        onCallbackParent();
      }
    }

  };

  return (
    <div style={{padding: 15}}>
      <Spin spinning={isLoading}>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={handleSubmit}
          scrollToFirstError
        >
          <Form.Item
            label="分组名称"
          >
            {group.name}
          </Form.Item>

          <Form.Item
            name="applicationIds"
            label="分组应用"
            initialValue={groupDetail.applicationIds}
            rules={[{ required: true, message: '请选择应用' }]}
          >
            <Select
              mode="multiple"
              placeholder="请选择分组应用"
              optionFilterProp="children"
              style={{ width: '100%' }}
            >
              {
                applications.map((app: any) => {
                  return <Option key={app.id} value={app.id}>{app.name}</Option>
                })
              }
            </Select>
          </Form.Item>

          <Form.Item
            name="authIds"
            label="分组权限"
            initialValue={groupDetail.authIds}
            rules={[{ required: true, message: '请选择权限' }]}
          >
            <Select
              mode="multiple"
              placeholder="请选择分组权限"
              style={{ width: '100%' }}
            >
              {
                auths.map((auth: any) => {
                  return <Option key={auth.id} value={auth.id}>{auth.describe}</Option>
                })
              }
            </Select>
          </Form.Item>

          <Form.Item
            name="userIds"
            label="分组成员"
            initialValue={groupDetail.userIds}
            rules={[{ required: true, message: '请选择成员' }]}
          >
            <Select
              mode="multiple"
              placeholder="请选择分组人员"
              optionFilterProp="children"
              style={{ width: '100%' }}
            >
              {
                users.map((user: any) => {
                  return <Option key={user.id} value={user.id}>{user.name}</Option>
                })
              }
            </Select>
          </Form.Item>

          <Form.Item {...tailFormItemLayout} style={{textAlign: 'center'}}>
            <Button type="primary" htmlType="submit">
              立即提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default inject("appStore")(observer(AssignGroupUnit));
