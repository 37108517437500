import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  AndroidOutlined,
  AppleOutlined,
  EyeOutlined,
  SettingOutlined,
  FormOutlined,
} from '@ant-design/icons';
import {
  Table,
  Tag,
  Button,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import { observer, inject } from 'mobx-react';
import LayoutHOC from '../../components/layout';
import DrawerUnit from '../../components/drawer';
import CreateVersionUnit from './create';
import UpdateVersionUnit from './update';
import ConfigureUnit from './configure';
import api from '../../services/api';
import './common.less';

declare let global: any;
// 初始化分页值
const initPagination = {
  current: 1,            // 当前页
  pageSize: 10,          // 每页显示条数
  total: 0,              // 总数
  showQuickJumper: true, // 快速跳转至某页
  showSizeChanger: true, // 是否展示pageSize切换器
  showTotal: (total: number) => { return `共${total}条` },
};

export interface IStates {
  loading: boolean,
  visible: boolean,
  subModalTitle: any,
  subModalComponent: any,
  selectedApplicationUUID: any,
  application: any,
  columns: any[],
  pagination: any,
}
@inject("appStore")
@observer
class Home extends Component<any, IStates> {
  constructor(props: any) {
    super(props);

    // 已选择的应用
    let selectedApplicationUUID = global.G_GET_APP_UUID();

    this.state = {
      loading: false,
      visible: false,
      subModalTitle: null,
      subModalComponent: null,
      selectedApplicationUUID,
      application: {
        isEnabledRedemptionCode: false,
        uuid: null,
        Versions: [],
      },
      columns: [],
      pagination: { // 分页
        ...initPagination
      },
    }
  }
  componentDidMount() {
    this.handleSetColumns();
    this.handleInit();
  }
  handleInit = () => {
    const { selectedApplicationUUID } = this.state;
    if (selectedApplicationUUID) {
      this.handleGetVersions(selectedApplicationUUID);
    }
  }
  /**
   * 表格分页序号处理
   * @param index 
   * @returns 
   */
  handleTableIndex = (index: number) => {
    let { pagination } = this.state;
    return `${(pagination.current - 1) * pagination.pageSize + (index + 1)}`;
  }
  /**
   * 分页、排序、筛选变化
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    if (extra.action === 'paginate') {//  分页
      this.setState({ pagination: { ...pagination, showTotal: (total: number) => { return `共${total}条` } } })
    }
  }
  /**
   * 列表设置
   */
  handleSetColumns = () => {
    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 62,
        fixed: 'left',
        render: (text: any, record: any, index: number) => this.handleTableIndex(index),
      },
      {
        title: '版本号',
        dataIndex: 'number',
        key: 'number',
        width: 100,
        fixed: 'left',
        render: (text: any) => <strong>V{text || '-'}</strong>,
      },
      {
        title: '更新类型',
        dataIndex: 'type',
        key: 'type',
        width: 100,
        render: (text: any) => <span>{global.G_VERSION_TYPE[text] || '-'}</span>,
      },
      {
        title: '更新主题',
        dataIndex: 'subject',
        key: 'subject',
        width: 120,
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '更新内容',
        dataIndex: 'content',
        key: 'content',
        render: (text: any) => <span>{text}</span>,
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        width: 80,
        filters: [
          {
            text: '待发布',
            value: 'normal',
          },
          {
            text: '已发布',
            value: 'published',
            tagColor: 'green'
          },
          {
            text: '已删除',
            value: 'deleted',
          }
        ],
        onFilter: (value: any, record: any) => record.status.indexOf(value) === 0,
        render: (text: any) => {
          let thisStatus = global.G_CONFIG.enum.versionStatus.find((o:any) => o.value === text);
          if (thisStatus) {
            return thisStatus.text;
          } else {
            return '-';
          }
        },
      },
      {
        title: 'APP下载',
        dataIndex: 'download',
        key: 'download',
        width: 100,
        render: (text: any, record: any) => (
          <div>
            {
              record.androidUrl ?
                <a href={record.androidUrl} target="_bank">
                  <Tag color="#2DDD71" className="d-tag-download" icon={<AndroidOutlined />}>安卓下载</Tag>
                </a>
              :
                <Tag color="#CCC" className="d-tag-download" icon={<AndroidOutlined />}>安卓下载</Tag>
            }
            {
              record.iosUrl ?
                <a href={record.iosUrl} target="_bank">
                  <Tag color="#000100" className="d-tag-download" icon={<AppleOutlined />}>IOS下载</Tag>
                </a>
              :
                <Tag color="#CCC" className="d-tag-download" icon={<AppleOutlined />}>IOS下载</Tag>
            }
            {/* <Popover
              content={
                <div style={{padding: 15, textAlign: 'center'}}>
                  <QRCode value={`${global.G_CONFIG.serverHost}/share/api/applications/${applicationUUID}/redemption-code`} renderAs="canvas" />
                  <div>IOS APP下载</div>
                </div>
              }
            >
              <Tag color="#000100" className="d-tag-download" style={{cursor: 'pointer'}} icon={<QrcodeOutlined />}>IOS兑换码</Tag>
            </Popover> */}
          </div>
        ),
      },
      {
        title: '上传者',
        dataIndex: 'uploader',
        key: 'uploader',
        width: 80,
        render: (text: any, record: any) => (
          <span>{ record.Uploader ? record.Uploader.name : '-' }</span>
          )
        },
      {
        title: '发布时间',
        dataIndex: 'sendTime',
        key: 'sendTime',
        width: 150,
        render: (text: any) => (
          <span>{ text ? dayjs(text).format('YYYY-MM-DD HH:mm') : '-'}</span>
        )
      },
      {
        title: '操作',
        dataIndex: 'options',
        key: 'options',
        width: 80,
        fixed: 'right',
        render: (text: any, record: any) => (
          <Button type="link" danger size="small" onClick={() => this.handleCreateNewVersionWindow('update', record)}>编辑</Button>
        )
      },
    ];
    this.setState({ columns });
  }
  /**
   * 获取所有版本
   * @param applicationUUID 
   */
  handleGetVersions = async (applicationUUID: any) => {
    let currentApplicationUUID = applicationUUID || this.state.selectedApplicationUUID;

    this.setState({ loading: true });

    let { errcode, data }: any = await api.getApplicationVersions(currentApplicationUUID);

    if (errcode >= 0) {
      this.setState({
        application: data.application,
        selectedApplicationUUID: currentApplicationUUID,
        visible: false,
        loading: false,
        subModalTitle: null,
        subModalComponent: null,
      })
    }

  }
  /**
   * 创建新版本
   * @param type 
   * @param record 
   */
  handleCreateNewVersionWindow = async (type: string, record?: any) => {

    if (type === 'create') {
      this.setState({
        visible: true,
        subModalTitle: '新建版本发布记录',
        subModalComponent: <CreateVersionUnit onCallbackParent={this.handleGetVersions} />,
      })
    } else if (type === 'update') {
      this.setState({
        visible: true,
        subModalTitle: '更新版本发布记录',
        subModalComponent: <UpdateVersionUnit version={record} onCallbackParent={this.handleGetVersions} />,
      })
    } else if (type === 'configure') {
      this.setState({
        visible: true,
        subModalTitle: '应用设置',
        subModalComponent: <ConfigureUnit application={this.state.application} onCallbackParent={this.handleInit} />,
      })
    } else {
      this.setState({
        visible: false,
        subModalTitle: null,
        subModalComponent: null,
      })
    }

  }
  render() {
    const { state } = this;
    let { name: userName } = global.G_LOCALSTORAGE.get('_USER_INFO');

    return (
      <div className="d-gateway">
        <div className="d-gateway-right">
          <div className="d-greeting">
            <div className="d-greeting-left">{global.G_TODAY_GREETING()}，{userName}同学！</div>
            <div className="d-greeting-right">
              {
                state.selectedApplicationUUID && (
                  <Tooltip title="点击进入版本预览列表">
                    <Link to={`/view/applications/${state.selectedApplicationUUID}/versions`} target="_blank">
                      <Button
                        type="dashed"
                        icon={<EyeOutlined />}
                        style={{marginLeft: 5}}
                      >
                        版本预览
                      </Button>
                    </Link>
                  </Tooltip>
                )
              }
              <Button
                type="dashed"
                icon={<SettingOutlined />}
                style={{marginLeft: 5}}
                disabled={!state.selectedApplicationUUID}
                onClick={() => this.handleCreateNewVersionWindow('configure')}
              >
                应用设置
              </Button>
              <Button
                type="primary"
                icon={<FormOutlined />}
                style={{marginLeft: 5}}
                disabled={!state.selectedApplicationUUID}
                onClick={() => this.handleCreateNewVersionWindow('create')}
              >
                创建新版本
              </Button>
            </div>
          </div>
          <Table
            sticky
            columns={state.columns}
            dataSource={state.application.Versions}
            rowKey={record => record.id}
            loading={state.loading}
            bordered
            size='middle'
            onChange={this.handleTableChange}
            pagination={state.pagination}
            scroll={{x: 1200}}
            rowClassName={
              (record) => {
                let classNameStr = '';
                if (record.status === 'published') classNameStr = 'd-version-bg-success';
                if (record.status === 'deleted') classNameStr = 'd-version-bg-pink';
                return classNameStr;
              }
            }
          />
        </div>
        <DrawerUnit
          title={state.subModalTitle}
          isVisible={state.visible}
          width="60%"
          callbackParent={() => this.handleCreateNewVersionWindow('close')}
        >
          { state.subModalComponent }
        </DrawerUnit>
      </div>
    );
  }
}

export default LayoutHOC(Home);
