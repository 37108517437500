import React, { Component } from 'react';
import {
  Table,
  Button,
  Tag
} from 'antd';

import DrawerUnit from '../../../components/drawer';
import CreateUserUnit from './create';
import UpdateUserUnit from './update';
import api from '../../../services/api';

declare let global: any;

export interface IStates {
  loading: boolean,
  users: any,
  visible: boolean,
  subModalTitle: any,
  subModalComponent: any,
  pagination: any,
  columns: any[],
}

// 初始化分页值
const initPagination = {
  current: 1,            // 当前页
  pageSize: 10,          // 每页显示条数
  total: 0,              // 总数
  showQuickJumper: true, // 快速跳转至某页
  showSizeChanger: true, // 是否展示pageSize切换器
  showTotal: (total: number) => { return `共${total}条` },
};
class AuthUnit extends Component<any, IStates> {

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      users: [],
      visible: false,
      subModalTitle: null,
      subModalComponent: null,
      columns: [],
      pagination: { // 分页
        ...initPagination
      },
    }
  }

  componentDidMount() {
    this.handleSetColumns();
    this.handleGetUsers();
  }

  // 获取所有人员
  handleGetUsers = async () => {
    let { errcode, data }: any = await api.getUsers();

    if (errcode >= 0) {
      this.setState({
        users: data.users
      });

      this.handleOpenDrawerWindow();
    }

  }

  handleOpenDrawerWindow = (type?: string, record?: any) => {
    if (type === 'createUser') {
      this.setState({
        visible: true,
        subModalTitle: '新增人员',
        subModalComponent: <CreateUserUnit onCallbackParent={this.handleGetUsers} />,
      })
    } else if (type === 'updateUser') {
      this.setState({
        visible: true,
        subModalTitle: '更新人员',
        subModalComponent: <UpdateUserUnit user={record} onCallbackParent={this.handleGetUsers} />,
      })
    } else {
      this.setState({
        visible: false,
        subModalTitle: null,
        subModalComponent: null,
      })
    }
  }
  /**
   * 表格分页序号处理
   * @param index 
   * @returns 
   */
  handleTableIndex = (index: number) => {
    let { pagination } = this.state;
    return `${(pagination.current - 1) * pagination.pageSize + (index + 1)}`;
  }
  /**
   * 分页、排序、筛选变化
   * @param pagination 
   * @param filters 
   * @param sorter 
   * @param extra 
   */
  handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    if (extra.action === 'paginate') {//  分页
      this.setState({ pagination: { ...pagination, showTotal: (total: number) => { return `共${total}条` } } })
    }
  }
  /**
   * 列表设置
   */
  handleSetColumns = () => {
    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 62,
        render: (text: any, record: any, index: number) => this.handleTableIndex(index),
      },
      {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
        width: 100,
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '手机号',
        dataIndex: 'mobile',
        key: 'mobile',
        width: 150,
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '邮箱',
        dataIndex: 'email',
        key: 'email',
        width: 150,
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '工号',
        dataIndex: 'jobNumber',
        key: 'jobNumber',
        width: 100,
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        width: 80,
        filters: [
          {
            text: '正常',
            value: 'normal',
          },
          {
            text: '未激活',
            value: 'inactive',
          },
          {
            text: '已删除',
            value: 'deleted',
          },
        ],
        onFilter: (value: any, record: any) => record.status.indexOf(value) === 0,
        render: (text: any) => {

          let thisStatus = global.G_CONFIG.enum.normalStatus.find((o:any) => o.value === text);

          if (thisStatus) {
            return <Tag color={thisStatus.tagColor}>{thisStatus.text}</Tag>
          } else {
            return '-';
          }

        }
      },
      {
        title: '备注',
        dataIndex: 'describe',
        key: 'describe',
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '创建时间',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: any) => <span>{global.G_DATE_FORMAT(text, 'fullTimes')}</span>,
      },
      {
        title: '操作',
        dataIndex: 'options',
        key: 'options',
        width: 80,
        render: (text: any, record: any) => (
          <div>
            <Button danger type="link" size="small" onClick={this.handleOpenDrawerWindow.bind(this, 'updateUser', record)}>编辑</Button>
          </div>
        )
      },
    ];
    this.setState({ columns });
  }

  render() {
    const state = this.state;

    return (
      <div>
        <div className="d-greeting">
          <div className="d-greeting-left"></div>
          <div className="d-greeting-right">
            <Button
              type="primary"
              style={{marginLeft: 5}}
              onClick={this.handleOpenDrawerWindow.bind(this, 'createUser')}
            >
              新增人员
            </Button>
          </div>
        </div>
        <Table
          columns={state.columns}
          dataSource={state.users}
          rowKey={record => record.id}
          loading={state.loading}
          bordered
          size='middle'
          onChange={this.handleTableChange}
          pagination={state.pagination}
          rowClassName={
            (record) => {
              return ['inactive', 'stoped', 'deleted'].includes(record.status) ? 'd-bg-gray' : '';
            }
          }
        />
        <DrawerUnit
          title={state.subModalTitle}
          isVisible={state.visible}
          width="60%"
          callbackParent={this.handleOpenDrawerWindow.bind(this, 'close')}
        >
          { state.subModalComponent }
        </DrawerUnit>
      </div>
    )
  }
}

export default AuthUnit;