
import { observable, action, makeObservable, runInAction } from 'mobx';
import api from '../services/api';

class AppStore {

  @observable public applications: any[] = [];
  @observable public users: any[] = [];
  @observable public auths: any[] = [];

  @observable public qiniuToken?: string;
  @observable public appLoading?: boolean;

  constructor() {
    makeObservable(this); // 需要observable的值，默认全部（mobx6引入）
  }

  /**
   * 加载提示控制
   */
   @action.bound
  handleAppLoading() {
    this.appLoading = true;
    setTimeout(() => {
      this.appLoading = false;
    }, 200);
  }

  /**
   * 获取七牛token
   * @param type （forced 强制更新数据）
   */
  @action.bound
  async handleGetQiniuToken (type?: string) {

    let newQiniuToken = this.qiniuToken;

    if (!newQiniuToken || type === 'forced') {

      let res: any = await api.getQiniuToken();

      const { qiniuToken } = res.data;
  
      runInAction(() => {

        newQiniuToken = qiniuToken;
  
        this.qiniuToken = qiniuToken;
  
      })

    }

    return newQiniuToken;

  }

  /**
   * 获取所有应用（未使用）
   * @param type （forced 强制更新数据）
   */
  @action.bound
  async handleGetApplications (type: string) {

    let newApplications = this.applications;

    if (newApplications.length === 0 || type === 'forced') {

      let res: any = await api.getApplications();

      const { applications } = res.data;

      runInAction(() => {

        newApplications = applications;

        this.applications = applications;

      })

    }

    return newApplications;

  }

  /**
   * 获取所有人员（未使用）
   * @param type （forced 强制更新数据）
   */
  @action.bound
  async handleGetUsers (type: string) {

    let newUsers = this.users;

    if (newUsers.length === 0 || type === 'forced') {

      let res: any = await api.getUsers();

      const { users } = res.data;

      runInAction(() => {

        newUsers = users;

        this.users = users;

      })

    }

    return newUsers;

  }

  /**
   * 获取所有权限（未使用）
   * @param type （forced 强制更新数据）
   */
  @action.bound
  async handleGetAuths (type: string) {

    let newAuths = this.auths;

    if (newAuths.length === 0 || type === 'forced') {

      let res: any = await api.getAuths();

      const { auths } = res.data;

      runInAction(() => {

        newAuths = auths;

        this.auths = auths;

      })

    }

    return newAuths;

  }

}

export default new AppStore();