import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Spin,
  Form,
  Input,
  Steps,
  Button,
  message,
} from 'antd';
import {
  TagOutlined,
  UserOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons';
import WarningUnit from '../common/warning';
import api from '../../services/api';

declare let global: any;

const { Step } = Steps;
const formItemLayout = {
  labelCol: {
    sm: { span: 6 }
  },
  wrapperCol: {
    sm: { span: 14 }
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      span: 24
    },
  },
};

const BaselineDictionaryUnit = (props: any) => {

  const { application, isCreate } = props;
  
  const [form] = Form.useForm();
  const [versions, setVersions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleGetBaselineVersions();

    // eslint-disable-next-line
  }, [])

  // 获取版本列表
  const handleGetBaselineVersions = async () => {

    if (!application) return;

    let { uuid } = application;

    setIsLoading(true);

    let res: any = await api.getBaselineDictionaryVersions(uuid);

    setIsLoading(false);

    if (res.errcode >= 0) {
      setVersions(res.data.baselineDictionaryVersions);
    }

  }

  // 提交表单
  const handleSubmit = async (values: any) => {

    let { uuid } = application;
    let datas = {
      ...values,
    };

    setIsLoading(true);
    let res: any = await api.createBaselineDictionaryVersion(uuid, datas);
    setIsLoading(false);

    if (res.errcode >= 0) {

      message.success('基线版本创建成功！', 1);
      handleGetBaselineVersions();

      // 清空表单
      form.resetFields();

    }

  };

  return (
    <div style={{padding: 15}}>
      <Spin spinning={isLoading}>
        {
          isCreate && (
            <div style={{background: '#F7F7F7', padding: '30px 0 5px 0', borderRadius: 5, marginBottom: 15}}>
              <Form
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={handleSubmit}
                scrollToFirstError
              >
                <Form.Item
                  name="version"
                  label="版本号"
                  rules={[{ required: true, message: '请输入版本号！' }]}
                >
                  <Input placeholder="请输入版本号，如：1.0.0" />
                </Form.Item>

                <Form.Item
                  name="name"
                  label="标题"
                  rules={[{ required: true, message: '请输入标题！' }]}
                >
                  <Input placeholder="请输入标题，如：定期发布版本" />
                </Form.Item>

                <Form.Item
                  name="describe"
                  label="描述"
                >
                  <Input.TextArea rows={6} placeholder="请输入描述，如：新增基线功能" />
                </Form.Item>

                <Form.Item {...tailFormItemLayout} style={{textAlign: 'center'}}>
                  <Button type="primary" htmlType="submit">
                    立即提交
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )
        }
        {
          !isLoading && versions.length === 0 && <WarningUnit data="暂无数据" style={{paddingTop: '15%'}} />
        }
        <Steps progressDot current={0} direction="vertical">
          {
            versions.map((val: any) => {
              return (
                <Step
                  key={val.id}
                  title={<Link to={`/s/baseline/${val.uuid}/entry-tables`} target="_blank" className="d-link-dark"><b>{val.name}</b></Link>}
                  description={
                    <>
                      <div style={{color: '#606c80', padding: '5px 0', fontSize: 12}}>
                        <span style={{paddingRight: 8}}><UserOutlined /> {val.Creator.name}</span>
                        <span style={{paddingRight: 8}}><TagOutlined /> {val.version}</span>
                        <span style={{paddingRight: 8}}><FieldTimeOutlined /> {global.G_DATE_FORMAT(val.createdAt, 'fullTimes')}</span>
                      </div>
                      {val.describe}
                    </>
                  }
                />
              )
            })
          }
        </Steps>
      </Spin>
    </div>
  );
};

export default BaselineDictionaryUnit;
