import React, { Component } from 'react';
import {
  Table,
  Button,
  Tag,
  Tooltip,
} from 'antd';
import {
  AuditOutlined,
  EditOutlined,
} from '@ant-design/icons';
import DrawerUnit from '../../../components/drawer';
import CreateGroupUnit from './create';
import UpdateGroupUnit from './update';
import AssignGroupAuthUnit from './assignAuth';
import api from '../../../services/api';

declare let global: any;

export interface IStates {
  loading: boolean,
  groups: any,
  visible: boolean,
  subModalTitle: any,
  subModalComponent: any,
}

class AuthUnit extends Component<any, IStates> {

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      groups: [],
      visible: false,
      subModalTitle: null,
      subModalComponent: null,
    }
  }

  componentDidMount() {
    this.handleGetGroups();
  }

  // 获取所有分组
  handleGetGroups = async () => {
    let { errcode, data }: any = await api.getGroups();

    if (errcode >= 0) {
      this.setState({
        groups: data.groups
      });

      this.handleOpenDrawerWindow();
    }

  }

  handleOpenDrawerWindow = (type?: string, record?: any) => {
    if (type === 'createGroup') {
      this.setState({
        visible: true,
        subModalTitle: '新建分组',
        subModalComponent: <CreateGroupUnit onCallbackParent={this.handleGetGroups} />,
      })
    } else if (type === 'updateGroup') {
      this.setState({
        visible: true,
        subModalTitle: '更新分组',
        subModalComponent: <UpdateGroupUnit group={record} onCallbackParent={this.handleGetGroups} />,
      })
    } else if (type === 'assignAuth') {
      this.setState({
        visible: true,
        subModalTitle: '分配分组权限',
        subModalComponent: <AssignGroupAuthUnit group={record} onCallbackParent={this.handleGetGroups} />,
      })
    } else {
      this.setState({
        visible: false,
        subModalTitle: null,
        subModalComponent: null,
      })
    }
  }

  render() {
    const state = this.state;
    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 62,
        render: (text: any, record: any, index: number) => index + 1,
      },
      {
        title: '名称',
        dataIndex: 'name',
        key: 'name',
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '描述',
        dataIndex: 'describe',
        key: 'describe',
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        width: 80,
        filters: [
          {
            text: '正常',
            value: 'normal',
          },
          {
            text: '未激活',
            value: 'inactive',
          },
          {
            text: '已停用',
            value: 'stoped',
          },
          {
            text: '已删除',
            value: 'deleted',
          },
        ],
        onFilter: (value: any, record: any) => record.status.indexOf(value) === 0,
        render: (text: any) => {

          let thisStatus = global.G_CONFIG.enum.normalStatus.find((o:any) => o.value === text);

          if (thisStatus) {
            return <Tag color={thisStatus.tagColor}>{thisStatus.text}</Tag>
          } else {
            return '-';
          }

        }
      },
      {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        render: (text: any) => {
          if (text === 'preset') {
            return '普通预置';
          } else if (text === 'preset-admin') {
            return '管理预置';
          } else {
            return '普通';
          }
        }
      },
      {
        title: '创建时间',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: any) => <span>{global.G_DATE_FORMAT(text, 'fullTimes')}</span>,
      },
      {
        title: '操作',
        dataIndex: 'options',
        key: 'options',
        width: 100,
        render: (text: any, record: any) => {
          return (
            <>
            <Tooltip placement="top" title="编辑">
              <Button type="dashed" shape="circle" icon={<EditOutlined />} onClick={this.handleOpenDrawerWindow.bind(this, 'updateGroup', record)} />
            </Tooltip>
            <Tooltip placement="top" title="权限分配">
              <Button type="dashed" shape="circle" icon={<AuditOutlined />} onClick={this.handleOpenDrawerWindow.bind(this, 'assignAuth', record)} />
            </Tooltip>
            </>
          );

        }
      },
    ];

    return (
      <div>
        <div className="d-greeting">
          <div className="d-greeting-left"></div>
          <div className="d-greeting-right">
            <Button
              type="primary"
              style={{marginLeft: 5}}
              onClick={this.handleOpenDrawerWindow.bind(this, 'createGroup')}
            >
              新增分组
            </Button>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={state.groups}
          rowKey={record => record.id}
          loading={state.loading}
          bordered
          size='middle'
          rowClassName={
            (record) => {
              return ['inactive', 'stoped', 'deleted'].includes(record.status) ? 'd-bg-gray' : '';
            }
          }
        />
        <DrawerUnit
          title={state.subModalTitle}
          isVisible={state.visible}
          width="60%"
          callbackParent={this.handleOpenDrawerWindow.bind(this, 'close')}
        >
          { state.subModalComponent }
        </DrawerUnit>
      </div>
    )
  }
}

export default AuthUnit;