import React, { Fragment, useState } from 'react';
import {
  Spin,
  Form,
  Input,
  Upload,
  Alert,
  Select,
  Button,
  message,
  Tabs,
  Tag,
  Modal
} from 'antd';
import { InboxOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import api from '../../services/api';

declare let global: any;
const { TabPane } = Tabs;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    sm: { span: 6 }
  },
  wrapperCol: {
    sm: { span: 14 }
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      span: 24
    },
  },
};

const UpdateEntryTableUnit = (props: any) => {

  const { entryTable, onCallbackParent } = props;

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  // 提交表单
  const handleSubmit = async (values: any) => {

    let datas = {
      ...values,
    };
    let { id: entryTableId } = entryTable;

    if (!entryTableId) return message.error('获取表数据失败！', 1);

    setIsLoading(true);
    let res: any = await api.updateEntryTable(entryTableId, datas);
    setIsLoading(false);

    if (res.errcode >= 0) {

      message.success('数据表更新成功！');

      if (onCallbackParent) {
        onCallbackParent();
      }
    }

  };

  // 删除数据表
  const handleDelete = async () => {

    let currentSelectedApp = global.G_LOCALSTORAGE.get('_SELECT_APP');
    let { uuid: appUUID } = currentSelectedApp;
    let { id: entryTableId } = entryTable;

    if (!entryTableId || !appUUID) return message.error('获取表数据失败！', 1);

    Modal.confirm({
      title: '确定要删除数据表吗？',
      icon: <ExclamationCircleOutlined />,
      content: '删除后将不会恢复，请仔细确认后谨慎操作！',
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {

        setIsLoading(true);
        let res: any = await api.deleteEntryTable(appUUID, entryTableId);
        setIsLoading(false);
    
        if (res.errcode >= 0) {
    
          message.success('数据表删除成功！');
    
          if (onCallbackParent) {
            onCallbackParent();
          }
        }

      }
    });

  }

  // 上传成功
  const handleOnCallbackParent = (info: any) => {

    setIsLoading(false);

    let { errcode, data } = info.file.response;

    if (errcode >= 0) {

      let { errorRepeatTables = [], errorSheets = [] } = data;

      if (errorRepeatTables.length > 0 || errorSheets.length > 0) {
        Modal.warning({
          title: '温馨提示',
          width: 600,
          content: (
            <Fragment>
              <div style={{color: 'orange', fontSize: 16, marginBottom: 10}}>当前上传的文件存在瑕疵，请严格按照模板文件上传！</div>
              {
                errorRepeatTables.length > 0 && (
                  <div style={{margin: '5px 0'}}>
                    <span>存在重复的数据表有：</span>
                    {
                      errorRepeatTables.map((text: string, index: number) => {
                        return <Tag key={index} color="volcano">{text}</Tag>
                      })
                    }
                  </div>
                )
              }
              {
                errorSheets.length > 0 && (
                  <div style={{margin: '5px 0'}}>
                    <span>存在错误的Sheet有：</span>
                    {
                      errorSheets.map((text: string, index: number) => {
                        return <Tag key={index} color="volcano">{text}</Tag>
                      })
                    }
                  </div>
                )
              }
            </Fragment>
          ),
        });

        onCallbackParent(null, true); // 通知父组件

      } else if (onCallbackParent) {
        onCallbackParent(); // 通知父组件
      }

    }

  }

  let token = global.G_LOCALSTORAGE.get('_TOKEN');
  let { id: entryTableId } = entryTable;

  const uploadProps = {
    name: 'file',
    multiple: true,
    action: `${global.G_CONFIG.serverHost}/api/upload/entry-tables/${entryTableId}/entry-fields`,
    headers: {
      authorization: 'Bearer ' + token,
    },
    onChange(info: any) {
      const { status } = info.file;

      if (status === 'done') {
        setIsLoading(false);
        message.success(`${info.file.name} 上传成功！`, 1);
        handleOnCallbackParent(info);
      } else if (status === 'error') {
        setIsLoading(false);
        message.error(`${info.file.name} 上传失败！`, 1);
      }

    },
    beforeUpload(file: any) {

      setIsLoading(true);

      const isLt100M = file.size / 1024 / 1024 < 1;
      let isStop = true;

      if (!isLt100M) {
        message.error('上传文件应小于1MB！', 1);
        setIsLoading(false);
        isStop = false;
      }

      let fileName = file.name || '';
      let fileExt = fileName.substring(fileName.lastIndexOf("."), fileName.length).toLowerCase();

      if (!['.xls', '.xlsx'].includes(fileExt)) {
        message.error('请上传限定格式文件：.xls或.xlsx', 1);
        setIsLoading(false);
        isStop = false;
      }

      return isStop;

    },
  };

  return (
    <div style={{padding: 15}}>
      <Spin spinning={isLoading}>
        <Alert type="warning" showIcon message="根据不同方式进行数据表更新" />
        <Tabs defaultActiveKey="2">
          <TabPane tab="模板上传" key="1">
            <>

              <div style={{marginBottom: 15}}>
                模板上传包含数据表及其字段，请根据提供的模板填入正确的内容，点击<a href={global.G_CONFIG.uploadTemplateUrl.down} target="_blank" rel="noreferrer" download="数据表上传模板">下载模板</a>。
              </div>

              <div style={{marginBottom: 15}}>
                <div style={{textAlign: 'center'}}>
                  <img src="https://docs.cq-tct.com/funenc/fpm/upload-template-20210605_573.png" alt="" width="70%" />
                  <div style={{margin: '15px 0', color: 'gray'}}>（↑样例）</div>
                </div>
              </div>

              <div style={{padding: '0 15%'}}>
                <Upload.Dragger {...uploadProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">点击或拖拽文件至此区域上传</p>
                  <p className="ant-upload-hint">仅支持xlsx格式</p>
                </Upload.Dragger>
              </div>
            </>
          </TabPane>
          <TabPane tab="手动录入" key="2">
            <Form
              {...formItemLayout}
              form={form}
              name="register"
              onFinish={handleSubmit}
              initialValues={{
                status: 'normal',
                ...entryTable
              }}
              scrollToFirstError
            >
              <Form.Item
                name="name"
                label="表名"
                rules={[{ required: true, message: '请输入表名！' }]}
              >
                <Input placeholder="请输入表名，如：Projects" />
              </Form.Item>

              <Form.Item
                name="describe"
                label="表备注"
                rules={[{ required: true, message: '请输入表备注！' }]}
              >
                <Input placeholder="请输入表备注，如：项目表" />
              </Form.Item>

              <Form.Item
                name="status"
                label="状态"
                rules={[{ required: true, message: '请选择状态！' }]}
              >
                <Select placeholder="请选择状态">
                  <Option value="normal">正常</Option>
                  <Option value="inactive">未激活</Option>
                  <Option value="stoped">已停用</Option>
                  <Option value="deleted">已删除</Option>
                </Select>
              </Form.Item>

              <Form.Item {...tailFormItemLayout} style={{textAlign: 'center'}}>
                <Button type="primary" htmlType="submit">
                  立即提交
                </Button>
                <Button danger style={{marginLeft: 15}} onClick={handleDelete}>
                  删除
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </Spin>
    </div>
  );
};

export default UpdateEntryTableUnit;
