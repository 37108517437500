import React, { Component } from 'react';
import { Layout, Table, Input, Tag } from 'antd';
import { debounce as _debounce } from 'lodash';
import WarningUnit from '../common/warning';
import api from '../../services/api';
import './common.less';

declare let global: any;

export interface IStates {
  tableLoading: boolean,
  loading: boolean,
  selectedApplicationUUID: any,
  orginEntryTables: any,
  entryTables: any,
  selectedEntryTable: any,
  entryFields: any,
  contentHeight: number,
  application: any,
}

const { Header, Content, Footer } = Layout;

const initSelectedEntryTable = {
  id: null,
  name: null,
  describe: null
};

class ShareApplicationEntryTable extends Component<any, IStates> {
  constructor(props: any) {
    super(props);

    // 获取当前应用
    const { uuid: selectedApplicationUUID } = this.props.match.params;

    this.state = {
      tableLoading: false,
      loading: false,
      selectedApplicationUUID,
      entryTables: [], // 数据表
      orginEntryTables: [], // 数据表（用于筛选）
      application: {
        id: null,
        name: null,
        company: null,
        EntryTables: [],
      },
      selectedEntryTable: {
        ...initSelectedEntryTable
      }, // 当前选择的数据表
      entryFields: [], // 当前数据表字段
      contentHeight: 300,
    }
  }

  componentDidMount() {

    this.handleGetEntryTables(this.state.selectedApplicationUUID);
    this.handleWindowHeight();

    window.onresize = _debounce(() => {
      this.handleWindowHeight();
    }, 100);

  }

  handleWindowHeight = () => {
    let contentHeight = window.innerHeight - 240;
    this.setState({ contentHeight });
  }

  /**
   * 获取数据表
   * @param applicationUUID 应用UUID
   */
  handleGetEntryTables = async (applicationUUID?: any) => {

    let currentApplicationUUID = applicationUUID || this.state.selectedApplicationUUID;

    this.setState({ tableLoading: true });

    let { errcode, data }: any = await api.SHARE_getApplicationEntryTables(currentApplicationUUID);

    if (errcode >= 0) {

      let refreshHash: any = {
        application: data.application,
        orginEntryTables: data.application.EntryTables,
        entryTables: data.application.EntryTables,
        tableLoading: false,
      };

      this.setState({...refreshHash});
    }

  }

  // 选中数据表
  handleSelectEntryTable = async (entryTable: any) => {
    let { id } = this.state.selectedEntryTable;
    if (entryTable && entryTable.id !== id) {
      this.setState({ selectedEntryTable: entryTable });
      this.handleGetEntryFields(entryTable.id);
    }
  }

  // 获取数据表字段
  handleGetEntryFields = async (entryTableId: any) => {

    let currentEntryTableId = entryTableId || this.state.selectedEntryTable.id;

    this.setState({ loading: true });

    let { errcode, data }: any = await api.SHARE_getEntryTableEntryFields(currentEntryTableId);

    if (errcode >= 0) {
      this.setState({
        entryFields: data.entryTable.EntryFields,
        loading: false,
      })
    }

  }

  // 数据表查询
  handleOnChangeTableName = (e: any) => {

    const { orginEntryTables } = this.state;
    const { value } = e.target;

    let entryTables = [];

    if (value) {

      entryTables = orginEntryTables.filter((o: any) => {

        let orginName = o.name.toLowerCase();
        let orginDescribe = o.describe.toLowerCase();
        let searchName = value.toLowerCase();
        return orginName.indexOf(searchName) >= 0 || orginDescribe.indexOf(searchName) >= 0;

      });

    } else {

      entryTables = orginEntryTables;

    }

    this.setState({ entryTables });

  }

  componentWillUnmount() {
    window.onscroll = null;
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const state = this.state;
    const thisYear = (new Date()).getFullYear();
    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 62,
        render: (text: any, record: any, index: number) => index + 1,
      },
      {
        title: '字段名',
        dataIndex: 'fieldName_us',
        key: 'fieldName_us',
        width: 150,
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '中文名',
        dataIndex: 'fieldName_zh',
        key: 'fieldName_zh',
        width: 150,
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        width: 100,
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '缺省值',
        dataIndex: 'initValue',
        key: 'initValue',
        width: 100,
        render: (text: any) => <span style={{whiteSpace: "pre-line"}}>{text || 'NULL'}</span>,
      },
      {
        title: '解析',
        dataIndex: 'analysis',
        key: 'analysis',
        render: (text: any) => <span style={{whiteSpace: "pre-line"}}>{text || '-'}</span>,
      },
      {
        title: '备注',
        dataIndex: 'remark',
        key: 'remark',
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        width: 80,
        filters: [...global.G_CONFIG.enum.normalStatus],
        onFilter: (value: any, record: any) => record.status.indexOf(value) === 0,
        render: (text: any) => {

          let thisStatus = global.G_CONFIG.enum.normalStatus.find((o:any) => o.value === text);

          if (thisStatus) {
            return <Tag color={thisStatus.tagColor}>{thisStatus.text}</Tag>
          } else {
            return '-';
          }

        }
      },
    ];

    return (
      <Layout className="d-layout">
          <Header className="d-layout-header-container">
            <div style={{fontSize: 20, padding: '0 15px'}}>
              <span className="d-logo">
                <img src='https://docs.cq-tct.com/funenc/transparent-logo.png' alt="logo" />
              </span>
              <span style={{marginLeft: 15}}>数据字典 · </span>
              <span style={{color: 'rgba(255, 255, 255, 0.5)'}}>
                {
                  state.application.name ? state.application.name : '*'
                }
              </span>
            </div>
          </Header>
          <Content className="d-layout-body-container">
          <div className="d-gateway d-content-container">
            <div className="d-gateway-left">
              <div className="d-dic-menu">
                <div className="d-dic-search">
                  <Input
                    placeholder={`输入表名模糊搜索，当前共${state.orginEntryTables.length}个表`}
                    allowClear
                    onChange={this.handleOnChangeTableName}
                  />
                </div>
                <div className="d-dic-fileds">

                  {
                    !state.tableLoading && state.entryTables.length === 0 && (
                      <WarningUnit data="暂无数据" style={{paddingTop: '15%'}} />
                    )
                  }

                  {
                    state.tableLoading && (
                      <WarningUnit mode="loading" data="数据加载中..." />
                    )
                  }

                  <ul>
                    {
                      state.entryTables.map((entryTable: any) => {
                        return (
                          <li
                            key={entryTable.id}
                            className={entryTable.id === state.selectedEntryTable.id ? 'd-field-selected' : ''}
                            onClick={this.handleSelectEntryTable.bind(this, entryTable)}
                          >
                            {entryTable.name} · {entryTable.describe}
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
            <div className="d-gateway-right">
              <div className="d-greeting">
                <div className="d-greeting-left">
                  {
                    state.selectedEntryTable.id ? (
                      <span>
                        数据表：{ state.selectedEntryTable.name || '-' }
                        <span style={{fontSize: 16}}>（{ state.selectedEntryTable.describe || '-' }）</span>
                      </span>
                    )
                    :
                      (
                        <span className="d-text-gray">请先选择左侧数据表</span>
                      )
                  }
                </div>
                <div className="d-greeting-right"></div>
              </div>
              <Table
                sticky
                columns={columns}
                dataSource={state.entryFields}
                rowKey={record => record.id}
                loading={state.loading}
                bordered
                pagination={false}
                rowClassName={
                  (record) => {

                    let classNameStr = '';

                    // if (record.status === 'published') classNameStr = 'd-bg-light-success';
                    if (['inactive', 'stoped', 'deleted'].includes(record.status)) classNameStr = 'd-bg-gray';

                    return classNameStr;
                  }
                }
              />
            </div>
          </div>
          </Content>
          <Footer className="d-layout-footer">北京富能通科技有限公司技术支持 ©2021~{thisYear}</Footer>
        </Layout>

    );
  }
}

export default ShareApplicationEntryTable;
