/*
 * @Description: 
 * @Author: qingzi.wang
 * @Date: 2021-06-07 19:45:01
 * @LastEditTime: 2022-08-17 15:51:38
 */
import React, { Component } from 'react';
import LayoutHOC from '../../components/layout';
import { message, Menu } from 'antd';
import GroupUnit from './group/home';
import AuthUnit from './auth/home';
import ApplicationUnit from './application/home';
import UserUnit from './user/home';
import WarningUnit from '../common/warning';
import {
  AppstoreOutlined,
  UsergroupAddOutlined,
  AuditOutlined,
  UngroupOutlined
} from '@ant-design/icons';

declare var global: any;

export interface IStates {
  loading: boolean
}

class Home extends Component<any, IStates> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
    }
  }

  componentDidMount() {

    let { Auths = [] } = global.G_LOCALSTORAGE.get('_USER_INFO');

    let isConsoleAuth = Auths.includes('SYSTEM_ADMINISTRATOR'); // 需要有管理权限

    if (!isConsoleAuth) {
      message.warning('当前您无权限访问管理控制台页面！', 1);
      this.props.history.push('/');
    }

  }
  /**
   * 选择菜单
   */
  handleSelectMenuChange = (e: any) => {
    this.props.history.push(`/console/${e.key}`);
  }

  render() {
    const { type } = this.props.match.params;

    return (
      <div className="d-gateway">
        <div className="d-gateway-left" style={{maxWidth: 300}}>
          <Menu
            mode="inline"
            style={{height: '100%'}}
            selectedKeys={[type]}
            onSelect={this.handleSelectMenuChange}
          >
            <Menu.Item key="group" icon={<UngroupOutlined />}>分组管理</Menu.Item>
            <Menu.Item key="auth" icon={<AuditOutlined />}>权限管理</Menu.Item>
            <Menu.Item key="application" icon={<AppstoreOutlined />}>应用管理</Menu.Item>
            <Menu.Item key="user" icon={<UsergroupAddOutlined />}>人员管理</Menu.Item>
          </Menu>
        </div>
        <div className="d-gateway-right">
          {
            type === 'group' && <GroupUnit />
          }
          {
            type === 'user' && <UserUnit />
          }
          {
            type === 'application' && <ApplicationUnit />
          }
          {
            type === 'auth' && <AuthUnit />
          }
          {
            !type && <WarningUnit data="请选择左侧菜单项" style={{paddingTop: '10%'}} />
          }
        </div>
      </div>
    );
  }
}

export default LayoutHOC(Home, <div />);
