import React, { useState } from 'react';
import {
  Spin,
  Form,
  Input,
  Button,
  message,
} from 'antd';
import api from '../../services/api';
import LayoutHOC from '../../components/layout';

const formItemLayout = {
  labelCol: {
    sm: { span: 8 }
  },
  wrapperCol: {
    sm: { span: 10 }
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      span: 24
    },
  },
};

const Home = (props: any) => {

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  // 处理
  const handleSubmit = async (values: any) => {

    const { newPassword, newPasswordConfirm } = values;

    if (newPassword !== newPasswordConfirm) {
      return message.error('确认密码两次输入不一致', 1);
    }

    let datas = {
      ...values,
    };

    setIsLoading(true);
    let res: any = await api.changePassword(datas);
    setIsLoading(false);

    if (res.errcode >= 0) {

      message.success('密码修改成功！', 1);

      props.history.push('/');

    }

  }

  return (
    <div style={{width: '100%', textAlign: 'center', padding: '6% 15px 0 15px'}}>
      <Spin spinning={isLoading}>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={handleSubmit}
          scrollToFirstError
        >
          <Form.Item
            name="oldPassword"
            label="旧密码"
            hasFeedback
            rules={[{ required: true, message: '请输入旧密码！' }]}
          >
            <Input placeholder="请输入旧密码" />
          </Form.Item>

          <Form.Item
            name="newPassword"
            label="新密码"
            hasFeedback
            rules={[{ required: true, message: '请输入新密码！' }]}
          >
            <Input placeholder="请输入新密码" />
          </Form.Item>

          <Form.Item
            name="newPasswordConfirm"
            label="新密码确认"
            hasFeedback
            dependencies={['newPassword']}
            rules={[
              { required: true, message: '请输入新密码！' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('两次输入的密码不一致!'));
                },
              }),
            ]}
          >
            <Input placeholder="请输入新密码确认" />
          </Form.Item>

          <Form.Item {...tailFormItemLayout} style={{textAlign: 'center'}}>
            <Button type="primary" htmlType="submit">
              立即提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default LayoutHOC(Home);
