/*
 * @Descripttion: 全局配置（仅必要设成全局）
 * @Author: qingzi.wang
 * @Date: 2021-03-08 17:42:23
 */ 
import dayjs from 'dayjs';
import enumConfig from './enum.d';
import storage from './localstorage';

const globalAny: any = global;
const { NODE_ENV } = process.env;

// 服务端地址
let serverHost = (NODE_ENV === 'production') ? 'https://idrs.antjob.ink' : 'http://127.0.0.1:8779';

/**
 * 通用配置
 */
globalAny.G_CONFIG = {
  serverHost,
  // 系统常用配置
  system: {
    name: 'iDRS数字资源仓库',
    logoUrl: 'https://docs.cq-tct.com/funenc/transparent-logo.png',
    subName: ''
  },
  // 操作手册
  manualUrl: 'https://rkwqgcnjpr.feishu.cn/docx/EpfGdwLynoDhBmxEWDwcPwGqnPd',
  // 文件上传地址
  docHost: 'https://docs.cq-tct.com',
  // 文件直传、模板下载地址
  uploadTemplateUrl: {
    up: '',
    down: 'https://docs.cq-tct.com/fpm/upload-template/%E6%95%B0%E6%8D%AE%E5%AD%97%E5%85%B8%E4%B8%8A%E4%BC%A0%E6%A8%A1%E6%9D%BF%EF%BC%88%E7%A4%BA%E4%BE%8B%EF%BC%89.xlsx',
  },
  // IOS兑换码模板下载地址
  uploadRedemptionCodeTemplateUrl: {
    up: '',
    down: 'https://docs.cq-tct.com/funenc%2Ffpm%2FIOS%E5%85%91%E6%8D%A2%E7%A0%81%E6%A8%A1%E7%89%88.xls',
  },
  // 枚举值
  enum: enumConfig,
}

// 全局自定义props对象
globalAny._PROPS = null;

// 拆分url的params部分
globalAny.G_SPLIT_URL_PARAMS = () => {

  let nowUrl = window.location.pathname;
  let nowUrlArray = nowUrl.split('?')[0].split('/'); //根目录会是两个空串["",""]
  let realKeys = nowUrlArray.filter(Boolean);

  return realKeys;
};

// 设置网页标题
globalAny.G_SET_DOCUMENT_TITLE = (title) => {

  let titleStr = '富能通 - iDRS数字资源仓库';
  return document.title = title ? `${title} - ${titleStr}` : titleStr;

}

/**
 * 本地存储
 * _USER_INFO // 人员信息 => crypto-hash
 * _TOKEN  // 认证信息 crypto-string
 * _CONSOLE_MENU_KEY // 控制台菜单
 */
global.G_LOCALSTORAGE = storage;

/**
 * 获取应用UUID
 */
globalAny.G_GET_APP_UUID = () => {
  let currentSelectedApp = globalAny.G_LOCALSTORAGE.get('_SELECT_APP');

  if (currentSelectedApp && currentSelectedApp.uuid) {
    return currentSelectedApp.uuid;
  } else {
    return false;
  }

}

// 判断当前时间在什么时间段（早上、上午、中午、下午、晚上）
globalAny.G_TODAY_GREETING = () => {

  let currentDate = new Date();

  let currentHour = currentDate.getHours(); // 24小时制

  let greeting: string;

  if (currentHour < 6 || currentHour >= 22) {
    greeting = '夜深了';
  } else if (currentHour >= 18 && currentHour < 22) {
    greeting = '晚上好';
  } else if (currentHour >= 14 && currentHour < 18 ) {
    greeting = '下午好';
  } else if (currentHour >= 12 && currentHour < 14 ) {
    greeting = '中午好';
  } else if (currentHour >= 9 && currentHour < 12 ) {
    greeting = '上午好';
  } else if (currentHour > 6 && currentHour < 9 ) {
    greeting = '早上好';
  }

  return greeting;
};

// 版本记录类型枚举
globalAny.G_VERSION_TYPE = {
  normal: '一般更新',
  force: '强制更新',
}

// 时间格式化
globalAny.G_DATE_FORMAT = (time, type, defaultValue) => {
  let res;
  if (time) {
    switch (type) {
      case 'fullTimes':
        res = dayjs(time).format('YYYY-MM-DD HH:mm:ss');
        break;
      case 'fullTimeToMinute':
        res = dayjs(time).format('YYYY-MM-DD HH:mm');
        break;
      case 'fullTimeToMini':
        res = dayjs(time).format('YYYYMMDDHHmmss');
        break;
      case 'year':
        res = dayjs(time).format('YYYY');
        break;
      default:
        res = dayjs(time).format('YYYY-MM-DD');
        break;
    }
  } else {
    res = defaultValue || '/';
  }
  return res;
};