/*
 * @Description: 
 * @Author: qingzi.wang
 * @Date: 2021-09-29 14:58:06
 * @LastEditTime: 2023-01-03 14:58:20
 */
/**
 * 枚举值配置
 */

const enumConfig = {
  // 通用状态枚举值（必须）
  versionStatus: [
    {
      text: '待发布',
      value: 'normal',
      tagColor: 'green'
    },
    {
      text: '已发布',
      value: 'published',
      tagColor: 'green'
    },
    {
      text: '未激活',
      value: 'inactive',
      tagColor: 'gray'
    },
    {
      text: '已停用',
      value: 'stoped',
      tagColor: 'gold'
    },
    {
      text: '已删除',
      value: 'deleted',
      tagColor: 'red'
    },
  ],
  // 通用状态枚举值（必须）
  normalStatus: [
    {
      text: '正常',
      value: 'normal',
      tagColor: 'green'
    },
    {
      text: '未激活',
      value: 'inactive',
      tagColor: 'gray'
    },
    {
      text: '已删除',
      value: 'deleted',
      tagColor: 'red'
    },
    {
      text: '已停用',
      value: 'stoped',
      tagColor: 'gold'
    },
  ]
}

export default enumConfig;