import CryptoJS from "crypto-js";

const aseKey = "idrs-funenc"; // 🧜‍♀️秘钥

/**
 * 加密
 * @param {*} content 
 * @returns 
 */
const encrypt = (content: any) => {
  if (!content) return;
  const messageStr = content instanceof Object ? JSON.stringify(content) : content.toString()
  const ciphertext = CryptoJS.AES.encrypt(messageStr, aseKey).toString()
  return ciphertext
}

/**
 * 解密
 * @param {*} encryptStr 
 * @returns 
 */
const decrypt = (encryptStr: string) => {
  const bytes = CryptoJS.AES.decrypt(encryptStr, aseKey);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedData
}
/**
 * 处理本地信息（删除）
 * @param {*} name 
 */
const remove = (name: string) => {
  localStorage.removeItem(name);
  sessionStorage.removeItem(name);
};

/**
 * 处理本地信息（清理）
 */
const clear = () => {
  localStorage.clear();
  sessionStorage.clear();
};

/**
 * 处理本地信息（读）
 * @param {*} name 
 * @returns 
 */
const get = (name: string) => {

  let dataStr = sessionStorage.getItem(name) || localStorage.getItem(name);
  let hash = {};

  if (dataStr) {
    // 加入try-catch防止内容变化
    try {
      hash = JSON.parse(dataStr);

      let { type, data }: any = hash;
      let newData;

      // 如果是加密类型，需要进行解密后返回
      switch (type) {
        case "crypto-hash":
          newData = JSON.parse(decrypt(data));
          break;
        case "crypto-string":
          newData = decrypt(data);
          break;
        default:
          newData = data;
          break;
      }
      return newData;
    } catch (error) {
      return false;
    }
  }

  return false;
};

/**
 * 处理本地信息（写），统一使用对象{}包裹
 * _USER_INFO // 人员信息 => crypto-hash
 * _TOKEN  // 认证信息 crypto-string
 * _CONSOLE_MENU_KEY // 控制台菜单
 * @param name  名称
 * @param data 数据体
 * @param type  类型（如：crypto标识为加密对象）
 * @returns
 */
const set = (name: string, data: any, type?: string) => {

  let newData = data;

  if (type && ['crypto-hash', 'crypto-string'].includes(type)) {
    newData = encrypt(data);
  }

  let saveData = {
    type,
    data: newData,
  };

  localStorage.setItem(name, JSON.stringify(saveData));
  sessionStorage.setItem(name, JSON.stringify(saveData))

  return true;
};

export default {
  remove,
  clear,
  get,
  set
};