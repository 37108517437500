import React, { Component } from 'react';
import {  debounce as _debounce } from 'lodash';
import { Table, Button, message } from 'antd';
import LayoutHOC from '../../components/layout';
import DrawerUnit from '../../components/drawer';
import CreateOfficeAddInsUnit from './create';
import UpdateOfficeAddInsUnit from './update';
import api from '../../services/api';
import './common.less';

declare let global: any;

export interface IStates {
  loading: boolean,
  visible: boolean,
  subModalTitle: any,
  subModalComponent: any,
  selectedApplicationUUID: any,
  officeAddIns: any[],
  contentHeight: number,
}

class Home extends Component<any, IStates> {
  constructor(props: any) {
    super(props);

    // 已选择的应用
    let selectedApplicationUUID = global.G_GET_APP_UUID();

    this.state = {
      loading: false,
      visible: false,
      subModalTitle: null,
      subModalComponent: null,
      selectedApplicationUUID,
      officeAddIns: [],
      contentHeight: 300,
    }
  }

  componentDidMount() {

    const { selectedApplicationUUID } = this.state;

    this.handleWindowHeight();

    if (selectedApplicationUUID) {
      this.handleGetOfficeAddIns(selectedApplicationUUID);
    }

    window.onresize = _debounce(() => {
      this.handleWindowHeight();
    }, 100);

  }

  // 窗口高度
  handleWindowHeight = () => {
    let contentHeight = document.body.clientHeight - 300;
    this.setState({ contentHeight });
  }

  // 获取所有加载项记录
  handleGetOfficeAddIns = async (applicationUUID: any) => {
    let currentApplicationUUID = applicationUUID || this.state.selectedApplicationUUID;

    this.setState({ loading: true });

    let { errcode, data }: any = await api.getOfficeAddIns(currentApplicationUUID);

    if (errcode >= 0) {
      this.setState({
        officeAddIns: data.application.OfficeAddIns,
        selectedApplicationUUID: currentApplicationUUID,
        visible: false,
        loading: false,
        subModalTitle: null,
        subModalComponent: null,
      })
    }

  }

  // 创建新版本
  handleCreateNewVersionWindow = async (type: string, record: any) => {

    let { selectedApplicationUUID } = this.state;

    if (type === 'create') {
      this.setState({
        visible: true,
        subModalTitle: '新建版本记录',
        subModalComponent: <CreateOfficeAddInsUnit applicationUUID={selectedApplicationUUID} onCallbackParent={this.handleGetOfficeAddIns} />,
      })
    } else if (type === 'update') {

      // 判断字符大小（限制1MB）
      let recordStr = JSON.stringify(record);

      if (recordStr.length > 1024000) {
        return message.warning('当前记录字符过长（超过1MB）不建议从网页端进行操作，易导致浏览器窗口崩溃风险！', 1);
      }

      this.setState({
        visible: true,
        subModalTitle: '更新版本记录',
        subModalComponent: <UpdateOfficeAddInsUnit applicationUUID={selectedApplicationUUID} officeAddIn={record} onCallbackParent={this.handleGetOfficeAddIns} />,
      })
    } else {
      this.setState({
        visible: false,
        subModalTitle: null,
        subModalComponent: null,
      })
    }

  }

  render() {
    const { state } = this;
    const columns: any = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 62,
        fixed: 'left',
        render: (text: any, record: any, index: number) => index + 1,
      },
      {
        title: '版本号',
        dataIndex: 'version',
        key: 'version',
        width: 90,
        fixed: 'left',
        render: (text: any) => <strong>V{text || '-'}</strong>,
      },
      {
        title: '字段1',
        dataIndex: 'field001',
        key: 'field001',
        render: (text: any) => <div className="d-office-addins-table-td">{handleSubStringUnit(text)}</div>,
      },
      {
        title: '字段2',
        dataIndex: 'field002',
        key: 'field002',
        render: (text: any) => <div className="d-office-addins-table-td">{handleSubStringUnit(text)}</div>,
      },
      {
        title: '字段3',
        dataIndex: 'field003',
        key: 'field003',
        render: (text: any) => <div className="d-office-addins-table-td">{handleSubStringUnit(text)}</div>,
      },
      {
        title: '字段4',
        dataIndex: 'field004',
        key: 'field004',
        render: (text: any) => <div className="d-office-addins-table-td">{handleSubStringUnit(text)}</div>,
      },
      {
        title: '字段5',
        dataIndex: 'field005',
        key: 'field005',
        render: (text: any) => <div className="d-office-addins-table-td">{handleSubStringUnit(text)}</div>,
      },
      {
        title: '字段6',
        dataIndex: 'field006',
        key: 'field006',
        render: (text: any) => <div className="d-office-addins-table-td">{handleSubStringUnit(text)}</div>,
      },
      {
        title: '操作',
        dataIndex: 'options',
        key: 'options',
        width: 80,
        fixed: 'right',
        render: (text: any, record: any) => (
          <Button type="link" danger size="small" onClick={this.handleCreateNewVersionWindow.bind(this, 'update', record)}>编辑</Button>
        )
      },
    ];

    return (
      <div className="d-gateway">
        <div className="d-gateway-right">
          <div className="d-greeting">
            <div className="d-greeting-left"></div>
            <div className="d-greeting-right">
              <Button
                type="primary"
                style={{marginLeft: 5}}
                disabled={!state.selectedApplicationUUID}
                onClick={this.handleCreateNewVersionWindow.bind(this, 'create')}
              >
                创建新记录
              </Button>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={state.officeAddIns}
            rowKey={record => record.id}
            loading={state.loading}
            bordered
            scroll={{ x: 'calc(700px + 50%)', y: state.contentHeight }}
            pagination={{ size: 'small' }}
            rowClassName={
              (record) => {

                let classNameStr = '';

                if (record.status === 'published') classNameStr = 'd-bg-light-success';
                if (record.status === 'deleted') classNameStr = 'd-bg-pink';

                return classNameStr;
              }
            }
          />
        </div>
        <DrawerUnit
          title={state.subModalTitle}
          isVisible={state.visible}
          width="60%"
          callbackParent={this.handleCreateNewVersionWindow.bind(this, 'close')}
        >
          { state.subModalComponent }
        </DrawerUnit>
      </div>
    );
  }
}

// 针对长字符裁剪
const handleSubStringUnit = (text: any) => {

  if (text) {

    let newStr = text.toString();

    if (newStr.length > 255) {
      return newStr.substr(0, 255) + '...';
    } else {
      return newStr;
    }

  } else {
    return '-'
  }

}

export default LayoutHOC(Home);
