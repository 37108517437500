/*
 * @Descripttion: 
 * @Author: qingzi.wang
 * @Date: 2021-03-08 17:14:02
 */ 
import React from 'react';
import { Provider } from 'mobx-react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import stores from '../stores/index';
import App from '../pages/app';
import Login from '../pages/common/login';
import Welcome from '../pages/common/welcome';
import ChangePassword from '../pages/common/changePassword';
import ConsolePage from '../pages/console/home';
import Gateway from '../pages/version/home';
import Statistics from '../pages/statistics/home';
import DataDictionary from '../pages/dataDictionary/home';
import OfficeAddIns from '../pages/officeAddIns/home';
import ShareApplicationEntryTable from '../pages/share/applicationEntryTable';
import ShareBaselineEntryTable from '../pages/share/baselineDictionary';
import ErrorLog from '../pages/errorLog';
import NoMatch from '../pages/common/noMatch';

// 重置body背景
const { body } = document;
body.style.background = '#FFF';

const configRoutes = [
  {
    path: '/',
    exact: true,
    main: App,
  },
  {
    path: '/welcome',
    exact: true,
    main: Welcome,
    meta:{
      require: true
    }
  },
  {
    path: '/login',
    exact: true,
    main: Login,
    meta:{
      require: false
    }
  },
  {
    path: '/package',
    exact: true,
    main: Gateway,
    meta:{
      require: true
    }
  },
  {
    path: '/statistics',
    exact: true,
    main: Statistics,
    meta:{
      require: true
    }
  },
  {
    path: '/data-dictionary',
    exact: true,
    main: DataDictionary,
    meta:{
      require: true
    }
  },
  {
    path: '/office-add-ins',
    exact: true,
    main: OfficeAddIns,
    meta:{
      require: true
    }
  },
  {
    path: '/error-log',
    exact: true,
    main: ErrorLog,
    meta:{
      require: true
    }
  },
  {
    path: '/change-password',
    exact: true,
    main: ChangePassword,
    meta:{
      require: true
    }
  },
  {
    path: ['/console', '/console/:type'],
    exact: true,
    main: ConsolePage,
    meta:{
      require: true
    }
  },
  {
    path: '/s/applications/:uuid/entry-tables',
    exact: true,
    main: ShareApplicationEntryTable,
    meta:{
      require: true
    }
  },
  {
    path: '/s/baseline/:uuid/entry-tables',
    exact: true,
    main: ShareBaselineEntryTable,
    meta:{
      require: true
    }
  },
  {
    path: '*',
    exact: true,
    main: NoMatch,
  }
];

const Routes = () => (
  <Provider {...stores}>
    <Router>
      <Switch>
        {
          configRoutes.map((route, index) => {

            // 需要登录则检查是否存在token（此处不科学，仅渲染一次，登录触发后无法更新）
            // let token = global.G_LOCALSTORAGE.get('_TOKEN');
            // if (route.meta && route.meta?.require && !token && route.path !== '/login') {
            //   return <Redirect key={index} to='/login' />
            // } else {
              return <Route key={index} exact={route.exact} path={route.path} component={route.main} />
            // }

          })
        }
      </Switch>
    </Router>
  </Provider>
)

export default Routes;