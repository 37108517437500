import React, { useState } from 'react';
import {
  Spin,
  Form,
  Input,
  Button,
  message,
  Select,
} from 'antd';
import api from '../../../services/api';

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    sm: { span: 6 }
  },
  wrapperCol: {
    sm: { span: 14 }
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      span: 24
    },
  },
};

const UpdateUserUnit = (props: any) => {

  const { auth, onCallbackParent } = props;

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  // 提交表单
  const handleSubmit = async (values: any) => {

    let { id } = auth;
    let datas = {
      ...values,
    };

    if (!id) return message.warning('获取权限信息失败！', 1);

    setIsLoading(true);
    let res: any = await api.updateAuth(id, datas);
    setIsLoading(false);

    if (res.errcode >= 0) {

      message.success('更新权限成功！');

      if (onCallbackParent) {
        onCallbackParent();
      }
    }

  };

  return (
    <div style={{padding: 15}}>
      <Spin spinning={isLoading}>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          initialValues={{
            status: 'normal',
            ...auth
          }}
          onFinish={handleSubmit}
          scrollToFirstError
        >
          <Form.Item
            name="name"
            label="编码"
            rules={[{ required: true, message: '请输入权限编码！' }]}
            help={<small className="d-text-warning">不建议随意变更权限编码</small>}
          >
            <Input placeholder="请输入权限编码！" />
          </Form.Item>

          <Form.Item
            name="describe"
            label="名称"
            rules={[{ required: true, message: '请输入权限名称！' }]}
          >
            <Input placeholder={`请输入权限名称！`} />
          </Form.Item>

          <Form.Item
            name="status"
            label="状态"
            rules={[{ required: true, message: '请选择状态！' }]}
          >
            <Select placeholder="请选择状态">
              <Option value="normal">正常</Option>
              <Option value="inactive">未激活</Option>
              <Option value="stoped">已停用</Option>
              <Option value="deleted">已删除</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="type"
            label="类型"
            rules={[{ required: true, message: '请选择类型！' }]}
          >
            <Select placeholder="请选择类型">
              <Option value="normal">正常</Option>
              <Option value="preset">预置（普通）</Option>
              <Option value="preset-admin">预置（管理用）</Option>
            </Select>
          </Form.Item>

          <Form.Item {...tailFormItemLayout} style={{textAlign: 'center'}}>
            <Button type="primary" htmlType="submit">
              立即提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default UpdateUserUnit;
