import React, { Component } from 'react';
import {
  Table,
  Button,
  Tag
} from 'antd';

import DrawerUnit from '../../../components/drawer';
import CreateApplicationUnit from './create';
import UpdateApplicationUnit from './update';
import api from '../../../services/api';

declare let global: any;

export interface IStates {
  loading: boolean,
  applications: any,
  visible: boolean,
  subModalTitle: any,
  subModalComponent: any,
  columns: any[],
}

class ApplicationUnit extends Component<any, IStates> {

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      applications: [],
      columns: [],
      visible: false,
      subModalTitle: null,
      subModalComponent: null,
    }
  }

  componentDidMount() {
    this.handleSetColumns();
    this.handleGetApplications();
  }
  handleSetColumns = () => {
    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 62,
        render: (text: any, record: any, index: number) => index + 1,
      },
      {
        title: '应用名称',
        dataIndex: 'name',
        key: 'name',
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '描述',
        dataIndex: 'describe',
        key: 'describe',
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '所属公司',
        dataIndex: 'company',
        key: 'company',
        width: 150,
        render: (text: any) => <span>{text || '-'}</span>,
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        width: 80,
        filters: [
          {
            text: '正常',
            value: 'normal',
          },
          {
            text: '未激活',
            value: 'inactive',
          },
          {
            text: '已停用',
            value: 'stoped',
          },
          {
            text: '已删除',
            value: 'deleted',
          },
        ],
        onFilter: (value: any, record: any) => record.status.indexOf(value) === 0,
        render: (text: any) => {

          let thisStatus = global.G_CONFIG.enum.normalStatus.find((o:any) => o.value === text);

          if (thisStatus) {
            return <Tag color={thisStatus.tagColor}>{thisStatus.text}</Tag>
          } else {
            return '-';
          }

        }
      },
      {
        title: '创建时间',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: any) => <span>{global.G_DATE_FORMAT(text, 'fullTimes')}</span>,
      },
      {
        title: '操作',
        dataIndex: 'options',
        key: 'options',
        width: 80,
        render: (text: any, record: any) => (
          <div>
            <Button danger type="link" size="small" onClick={this.handleOpenDrawerWindow.bind(this, 'updateApplication', record)}>编辑</Button>
          </div>
        )
      },
    ];
    this.setState({ columns });
  }
  // 获取所有应用
  handleGetApplications = async () => {
    let { errcode, data }: any = await api.getApplications();

    if (errcode >= 0) {
      this.setState({
        applications: data.applications
      });

      this.handleOpenDrawerWindow();
    }

  }

  handleOpenDrawerWindow = (type?: string, record?: any) => {
    if (type === 'createApplication') {
      this.setState({
        visible: true,
        subModalTitle: '新建应用',
        subModalComponent: <CreateApplicationUnit onCallbackParent={this.handleGetApplications} />,
      })
    } else if (type === 'updateApplication') {
      this.setState({
        visible: true,
        subModalTitle: '更新应用',
        subModalComponent: <UpdateApplicationUnit application={record} onCallbackParent={this.handleGetApplications} />,
      })
    } else {
      this.setState({
        visible: false,
        subModalTitle: null,
        subModalComponent: null,
      })
    }
  }

  render() {
    const { state } = this;

    return (
      <div>
        <div className="d-greeting">
          <div className="d-greeting-left"></div>
          <div className="d-greeting-right">
            <Button
              type="primary"
              style={{marginLeft: 5}}
              onClick={this.handleOpenDrawerWindow.bind(this, 'createApplication')}
            >
              创建应用
            </Button>
          </div>
        </div>
        <Table
          columns={state.columns}
          dataSource={state.applications}
          rowKey={record => record.id}
          loading={state.loading}
          bordered
          size='middle'
          rowClassName={
            (record) => {
              return ['inactive', 'stoped', 'deleted'].includes(record.status) ? 'd-bg-gray' : '';
            }
          }
        />
        <DrawerUnit
          title={state.subModalTitle}
          isVisible={state.visible}
          width="60%"
          callbackParent={this.handleOpenDrawerWindow.bind(this, 'close')}
        >
          { state.subModalComponent }
        </DrawerUnit>
      </div>
    )
  }
}

export default ApplicationUnit;