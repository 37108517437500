import React, { useState } from 'react';
import {
  Spin,
  Upload,
  Alert,
  message,
} from 'antd';
import QRCode from 'qrcode.react';
import { InboxOutlined } from '@ant-design/icons';
import WarningUnit from '../../common/warning';

declare let global: any;

const UploadRedemptionCode = (props: any) => {
  const { application, onCallbackParent } = props;
  const [isLoading, setIsLoading] = useState(false);
  /**
   * 上传传成功
   * @param info 
   */
  const handleOnCallbackParent = (info: any) => {
    setIsLoading(false);
    let { errcode } = info.file.response;
    if (errcode >= 0 && onCallbackParent) {
      onCallbackParent(); // 通知父组件
    }
  }

  let selectedApplicationUUID = global.G_GET_APP_UUID();
  let token = global.G_LOCALSTORAGE.get('_TOKEN');

  const uploadProps = {
    name: 'file',
    multiple: false,
    action: `${global.G_CONFIG.serverHost}/api/upload/applications/${selectedApplicationUUID}/redemption-code`,
    headers: {
      authorization: `Bearer ${token}`,
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} 上传成功！`, 1);
        handleOnCallbackParent(info);
      } else if (status === 'error') {
        setIsLoading(false);
        message.error(`${info.file.name} 上传失败！`, 1);
      }
    },
    beforeUpload(file: any) {

      setIsLoading(true);

      const isLt20M = file.size / 1024 / 1024 < 20;
      let isStop = true;
      let fileName = file.name || '';
      let fileExt = fileName.substring(fileName.lastIndexOf("."), fileName.length).toLowerCase();

      if (!isLt20M) {
        message.error('上传文件应小于20MB！', 1);
        setIsLoading(false);
        isStop = false;
      }

      if (!['.xls', '.xlsx'].includes(fileExt)) {
        message.error('请上传限定格式文件：.xls或.xlsx', 1);
        setIsLoading(false);
        isStop = false;
      }

      return isStop;

    },
  };

  return (
    <>
      <div className="d-separator-title">IOS兑换码上传（可选）</div>
      <Spin spinning={isLoading}>
        {
          application.isEnabledRedemptionCode ?
            <>
              <Alert
                showIcon
                type="warning"
                message={
                  <span>主要包含兑换码字段（「Code」或「代码」），请根据提供的模板填入正确的内容，点击<a href={global.G_CONFIG.uploadRedemptionCodeTemplateUrl.down} target="_blank" rel="noreferrer" download="数据表上传模板">下载模板</a>。</span>
                }
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: "center",
                  margin: '15px 0',
                  padding: 15,
                  backgroundColor: '#F9F9F9',
                  borderRadius: 10
                }}
              >
                <div style={{textAlign: 'center',width: '50%'}}>
                  <img src="https://docs.cq-tct.com/funenc/fpm/upload-template-20220627_67.png" alt="" width="100%" />
                  <div style={{margin: '15px 0', color: 'gray'}}>↑样例，Apple Business导出格式</div>
                </div>
                <div style={{textAlign: 'center',width: '50%'}}>
                  <QRCode
                    value={`${global.G_CONFIG.serverHost}/share/api/applications/${application.uuid}/consume-redemption-code`}
                    renderAs="canvas"
                    style={{padding: 15, background: '#FFF', width: 200, height: 200}}
                  />
                  <div style={{margin: '15px 0', color: 'gray'}}>↑IOS APP下载</div>
                </div>
              </div>
              <div style={{padding: '0 15%', textAlign: 'center', marginBottom: 15}}>
                <Upload.Dragger {...uploadProps} disabled={isLoading}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">点击或拖拽文件至此区域上传</p>
                  <p className="ant-upload-hint">仅支持xlsx、xls格式</p>
                </Upload.Dragger>
              </div>
            </>
          :
            <WarningUnit data="请先开启IOS兑换码功能并生效" style={{paddingTop: 15}} />
        }
      </Spin>
    </>
  );
};

export default UploadRedemptionCode;
