import React, { useState } from 'react';
import {
  Spin,
  Form,
  Input,
  Button,
  message,
  Switch,
} from 'antd';
import api from '../../../services/api';

declare let global: any;

const formItemLayout = {
  labelCol: {
    sm: { span: 6 }
  },
  wrapperCol: {
    sm: { span: 14 }
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      span: 24
    },
  },
};

const CreateApplicationUnit = (props: any) => {

  const { onCallbackParent } = props;

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  // 提交表单
  const handleSubmit = async (values: any) => {

    let currentApplicationUUID = global.G_GET_APP_UUID();
    let datas = {
      ...values,
    };

    setIsLoading(true);
    let res: any = await api.createApplication(datas);
    setIsLoading(false);

    if (res.errcode >= 0) {

      message.success('应用添加成功！');

      if (onCallbackParent) {
        onCallbackParent(currentApplicationUUID);
      }
    }

  };

  return (
    <div style={{padding: 15}}>
      <Spin spinning={isLoading}>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={handleSubmit}
          scrollToFirstError
          initialValues={{
            isEnabledRedemptionCode: false
          }}
        >
          <Form.Item
            name="name"
            label="名称"
            rules={[{ required: true, message: '请输入应用名称！' }]}
          >
            <Input placeholder="请输入应用名称！" />
          </Form.Item>
          <Form.Item
            name="company"
            label="所属公司"
            rules={[{ required: true, message: '请输入所属公司名称！' }]}
          >
            <Input placeholder="请输入所属公司名称！" />
          </Form.Item>
          <Form.Item
            name="isEnabledRedemptionCode"
            label="是否启用IOS兑换码"
            valuePropName="checked"
          >
            <Switch checkedChildren="开" unCheckedChildren="关" defaultChecked />
          </Form.Item>
          <Form.Item
            name="describe"
            label="描述"
          >
            <Input.TextArea
              rows={6}
              placeholder={`请输入应用描述！`}
            />
          </Form.Item>

          <Form.Item {...tailFormItemLayout} style={{textAlign: 'center'}}>
            <Button type="primary" htmlType="submit">
              立即提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default CreateApplicationUnit;
