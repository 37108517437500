import React, { useState } from 'react';
import {
  Spin,
  Form,
  Input,
  Button,
  message,
  Select,
  Switch,
} from 'antd';
import api from '../../../services/api';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    sm: { span: 6 }
  },
  wrapperCol: {
    sm: { span: 14 }
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      span: 24
    },
  },
};

const UpdateApplicationUnit = (props: any) => {

  const { application, onCallbackParent } = props;

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  // 提交表单
  const handleSubmit = async (values: any) => {

    let { uuid } = application;
    let datas = {
      ...values,
    };

    if (!uuid) return message.warning('获取应用失败！', 1);

    setIsLoading(true);
    let res: any = await api.updateApplication(uuid, datas);
    setIsLoading(false);

    if (res.errcode >= 0) {

      message.success('应用更新成功！');

      if (onCallbackParent) {
        onCallbackParent();
      }
    }

  };

  return (
    <div style={{padding: 15}}>
      <Spin spinning={isLoading}>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          initialValues={{
            status: 'normal',
            ...application
          }}
          onFinish={handleSubmit}
          scrollToFirstError
        >
          <Form.Item
            name="name"
            label="名称"
            rules={[{ required: true, message: '请输入应用！' }]}
          >
            <Input placeholder="请输入应用名称！" />
          </Form.Item>
          <Form.Item
            name="company"
            label="所属公司"
            rules={[{ required: true, message: '请输入所属公司名称！' }]}
          >
            <Input placeholder="请输入所属公司名称！" />
          </Form.Item>
          <Form.Item
            name="isEnabledRedemptionCode"
            label="是否启用IOS兑换码"
            valuePropName="checked"
          >
            <Switch checkedChildren="开" unCheckedChildren="关" />
          </Form.Item>
          <Form.Item
            name="status"
            label="状态"
            rules={[{ required: true, message: '请选择状态！' }]}
          >
            <Select placeholder="请选择状态">
              <Option value="normal">正常</Option>
              <Option value="inactive">未激活</Option>
              <Option value="stoped">已停用</Option>
              <Option value="deleted">已删除</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="describe"
            label="描述"
          >
            <Input.TextArea
              rows={6}
              placeholder={`请输入应用描述！`}
            />
          </Form.Item>

          <Form.Item {...tailFormItemLayout} style={{textAlign: 'center'}}>
            <Button type="primary" htmlType="submit">
              立即提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default UpdateApplicationUnit;
